import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useNavigate, useLocation } from 'react-router-dom';
import './style.css';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { renderIconCar } from 'src/app/utils/mapService';
import VehicleTablecell from '../tables/VehicleTablecell';
import CircularProgress from '@material-ui/core/CircularProgress';
import { STATUS_API } from 'src/app/constant/config';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    cursor: 'pointer'
  }
}))(TableRow);

const VehicleTable = ({ getVehicle, setRoomID, getDriverInfo, vehicleType, carStatus }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery();
  const [rowActive, setRowActive] = useState(query.get('device_serial') || null);
  const statisticVehicleTracking = useSelector(state => state.vehicleSlice.statisticVehicleTracking);
  const statusGetListTracking = useSelector(state => state.vehicleSlice.statusGetListTracking)
  const listVehicleTracking = useSelector(state => state.vehicleSlice.listVehicleTracking);
  const listVehicle = useSelector(state => state.vehicleSlice.listVehicle);
 
  
  const handleRowClick = vehicle => {
    getVehicle(vehicle);
    getDriverInfo(vehicle)
    setRowActive(vehicle.device_serial);
    navigate(`/app/camera/streaming?device_serial=${vehicle.device_serial}`);
  };

  const renderVehicle = (listVehicle, listVehicleTracking) => {
    if (listVehicle === null) 
      return listVehicleTracking;
    return listVehicle;
  };

  const listVehicleMonitor = renderVehicle(listVehicle, listVehicleTracking);
  //console.log('carStatus, vehicleType, listVehicleMonitor ===>',carStatus, vehicleType, listVehicleMonitor);

  const renderBody = listVehicleMonitor?.length > 0 ? listVehicleMonitor?.map((row, index) => {
    if (carStatus || vehicleType) {
      if (carStatus && !vehicleType && carStatus === row.device_status) {
        return (
          <TableBody >
            <StyledTableRow
              className={row.device_serial === rowActive ? 'row-active' : ''}
              key={row.vehicle_id ?? row.id ?? index + 1}
              onClick={() => handleRowClick(row)}
            >
               <VehicleTablecell 
                row ={row}
                index ={index}
                // rowActive = {rowActive}
              />
              <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
              <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

            </StyledTableRow>
          </TableBody >
        )
      }
      if (!carStatus && vehicleType && vehicleType === row.device_type) {
        return (
          <TableBody >
            <StyledTableRow
              className={row.device_serial === rowActive ? 'row-active' : ''}
              key={row.vehicle_id ?? row.id ?? index + 1}
              onClick={() => handleRowClick(row)}
            >
               <VehicleTablecell 
                row ={row}
                index ={index}
                rowActive = {rowActive}
              />
              <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
              <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

            </StyledTableRow>
          </TableBody >
        )
      }
      if (carStatus && vehicleType && carStatus === row.device_status && vehicleType === row.device_type) {
        return (
          <TableBody >
            <StyledTableRow
              className={row.device_serial === rowActive ? 'row-active' : ''}
              key={row.vehicle_id ?? row.id ?? index + 1}
              onClick={() => handleRowClick(row)}
            >
               <VehicleTablecell 
                row ={row}
                index ={index}
                //rowActive = {rowActive}
              />
              <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
              <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

            </StyledTableRow>
          </TableBody >
        )
      }
    }
    else {
      return (
        <TableBody >
          <StyledTableRow
            className={row.device_serial === rowActive ? 'row-active' : ''}
            key={row.vehicle_id ?? row.id ?? index + 1}
            onClick={() => handleRowClick(row)}
          >
              <VehicleTablecell 
                row ={row}
                index ={index}
               // rowActive = {rowActive}
              />
            <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
            <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

          </StyledTableRow>
        </TableBody >
      )
    }

  }
  ) : <caption> Không có thiết bị </caption>

  return (
    <div>
      <TableContainer component={Paper} className="vehicle_table" style={{height:'530px'}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead
            style={{ background: '#C62222 !important', color: 'white !important' }}
          >
            <TableRow>
              <TableCell align="center">Biển số</TableCell>
              <TableCell align="center">Vận tốc (Km/h) </TableCell>
              <TableCell align="center">Thời gian</TableCell>
            </TableRow>
          </TableHead>
          {/* <TableBody>
            {listVehicleTracking && listVehicleTracking?.map((row, index) => {
              return (
                <StyledTableRow
                  className={row.device_serial === rowActive ? 'row-active' : ''}
                  key={row.vehicle_id ?? row.id ?? index + 1}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell align="center" style={{ display: 'flex', alignItems: 'center', verticalAlign: 'middle' }}>
                    <img alt="icon" class="table-icon-car" width="25px"
                         src={renderIconCar(row, statisticVehicleTracking)} />
                    <b>{row.license_plate}</b>
                  </TableCell>

                  <TableCell align="center">
                    {Math.floor(row.speed_gps) || '--'}
                  </TableCell>
                  <TableCell align="center">
                    {row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody> */}
          {statusGetListTracking !== STATUS_API.LOADING ? 
            renderBody 
            : 
          <caption >
            <div style={{ textAlign: 'center', padding: 20 }}> <CircularProgress /></div>
          </caption>}
        </Table>
      </TableContainer >
    </div>
  );
};

export default React.memo(VehicleTable);
