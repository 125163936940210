import React, {
  useEffect,
} from 'react';
import { Marker, Popup, useMap,Tooltip } from 'react-leaflet';
import 'leaflet-rotatedmarker';
import './style.css'
import { ContentCarInfo } from './ContentCarInfo';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  
}));

export const DraggableMarker = props => {
  const {
    position,
    handleMarkerClick,
    displayIcon1,
    markerRef,
    positionsInfoBox
  } = props;

  const classes = useStyles();

  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.openPopup();
    }
  }, []);

  return (
    <Marker
      eventHandlers={{
        click: () => handleMarkerClick(position)
      }}
      position={[Number(position?.lat), Number(position?.lng)]}
      ref={markerRef}
      icon={displayIcon1()}
      zIndexOffset={5}
    >
      <Popup
          position = {[Number(position?.lat), Number(position?.lng)]}
          maxHeight = {200}
          minWidth = {375}
      >
          <ContentCarInfo positionsInfoBox={positionsInfoBox}/>
      </Popup>
    </Marker>
  );
};
