export class Device {
    constructor() {
        this.serial = ""
        this.device_type_id = 0
        this.hardware_version = ""
        this.date_of_manufacture = ""
    }
}

export class DeviceEdit {
    constructor() {
        this.serial = ""
        this.device_type_id = 0
        this.hardware_version = ""
        this.date_of_manufacture = ""
    }
}