export class Sale {
    constructor() {
        this.agency_name = ""
        this.email = ""
        this.password =  ""
        this.confirm_password = ""
        this.phone = ""
        this.full_name = ""
        this.address = ""
        //dvt
        this.province_id = ""
        this.business_id = ""
    }
  }
  