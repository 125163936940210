export const deviceType = [
    {
        id: 1,
        name:'VGPS_CAR4G'
    },
    {
        id: 2,
        name:'VGPS_MOTO4G'
    },
    {
        id: 3,
        name:'VGPS_CAM4G'
    },
    {
        id: 4,
        name:'VGPS_HUM4G'
    },
]

export const vehicleType = [
    {
        id: 1,
        name:'Con người',
        license_plate:'',
        slots:[],
        tonnage:[],
    },
    {
        id: 2,
        name:'Xe máy',
        license_plate:'',
        slots:[],
        tonnage:[],
    },
    {
        id: 3,
        name:'Xe ô tô con',
        license_plate:'',
        slots:[],
        tonnage:[],
    }, 
    {
        id: 4,
        name:'Xe khách',
        license_plate:'',
        slots:[
            {   value: 7,
                name: '7 chỗ'
            }, 
            {   value: 9,
                name: '9 chỗ'
            }, 
            {   value: 16,
                name: '16 chỗ'
            }, 
            {   value: 24,
                name: '24 chỗ'
            }, 
            {   value: 29,
                name: '29 chỗ'
            }, 
            {   value: 35,
                name: '35 chỗ'
            }, 
            {   value: 45,
                name: '45 chỗ'
            }, 
        ],
        tonnage:[],
    },
    {
        id: 5,
        name:'Xe Container',
        license_plate:'',
        slots:[],
        tonnage:[],
    },
    {
        id: 6,
        name:'Xe tải',
        license_plate:'',
        slots:[],
        tonnage:[
            {
                value: 'Dưới 5T',
                name:'Dưới 5T', 
            },
            {
                value: 'Dưới 10T',
                name:'Dưới 10T', 
            },
            {
                value: 'Trên 10T',
                name:'Trên 10T', 
            },
        ],
    },
    {
        id: 7,
        name:'Xe bồn',
        license_plate:'',
        slots:[],
        tonnage:[],
    },
]