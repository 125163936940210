import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import Page from 'src/app/components/Page';
import './style.css';
import Loading from 'src/app/components/Loading';
import _orderBy from 'lodash/orderBy';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import MapOsm from './MapOsm';

const TrackingView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [params, setParams] = useState({
    id: 1,
    page: 1,
    pageSize: PAGE_SIZE_LIST
  });

  useEffect(() => {
    // dispatch(getListVehicleTracking(params))
  }, []);


  return (
    <Page className={classes.root}>
      <div className="flex">
        <div style={{
           width: '100%',
           height: 'calc(100% - 66px)',
           position: 'relative',
        }}>
            <MapOsm
              className="map"
              loadingElement={<Loading />}
            />
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingLeft: 0,
    paddingRight: 0
  },
  mapWrap: {
    width: '100%',
    height: 'calc(100% - 66px)',
    position: 'relative'
  }
}));

export default React.memo(TrackingView);
