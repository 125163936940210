export const listAgenciesMock= [
            {
                "id": 1,
                "name": "TAXI24H",
                "status": "active",
                "created_at": "2023-11-17T16:14:36.315Z",
                "updated_at": "2024-11-15T02:04:28.441Z",
                "agency_code": 1000,
                "config": "{ \"gpsSpeedCol\": false, \"speedCol\": false, \"kmCol\": false, \"doorStatusCol\": false, \"airConditionStatusCol\": false, \"engineStatusCol\": false, \"latLngCol\": false }",
                "leader": {
                    "id": 3,
                    "username": null,
                    "full_name": "TAXI24H",
                    "email": "support@taxi24h.vn",
                    "phone": "0333703617",
                    "citizen_id": 1,
                    "address": "1234",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2023-11-17T16:14:36.303Z",
                    "updated_at": "2024-11-04T08:31:04.000Z"
                },
                "business": {
                    "id": 7,
                    "title": "Kinh doanh vận tải hành khách bằng xe taxi",
                    "created_at": "2023-11-17T15:55:06.562Z",
                    "updated_at": "2023-11-17T15:55:06.562Z"
                }
            },
            {
                "id": 2,
                "name": "TAXI FIX fixfix fix",
                "status": "active",
                "created_at": "2024-11-11T09:32:10.689Z",
                "updated_at": "2024-11-15T03:54:57.000Z",
                "agency_code": 20004,
                "config": null,
                "leader": {
                    "id": 28,
                    "username": null,
                    "full_name": "NTAnh update",
                    "email": "aabb1234511111@gmail.com",
                    "phone": "0982222222",
                    "citizen_id": "20",
                    "address": "Hải Dương update",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-11T09:32:10.679Z",
                    "updated_at": "2024-11-15T03:54:57.000Z"
                },
                "business": {
                    "id": 3,
                    "title": "Kinh doanh vận tải hàng nguy hiểm",
                    "created_at": "2023-11-17T15:54:06.393Z",
                    "updated_at": "2023-11-17T15:54:06.393Z"
                }
            },
            {
                "id": 4,
                "name": "TAXI",
                "status": "active",
                "created_at": "2024-11-11T09:40:45.345Z",
                "updated_at": "2024-11-11T09:40:45.345Z",
                "agency_code": 30000,
                "config": null,
                "leader": {
                    "id": 30,
                    "username": "Update 123",
                    "full_name": "Update 123",
                    "email": "update1233@gmail.com",
                    "phone": "0332222222",
                    "citizen_id": 83,
                    "address": "Hải Dương 123123123",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-11T09:40:45.340Z",
                    "updated_at": "2024-11-14T03:38:46.000Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
            {
                "id": 5,
                "name": "TAXIHehe",
                "status": "active",
                "created_at": "2024-11-11T09:55:46.707Z",
                "updated_at": "2024-11-11T09:57:45.000Z",
                "agency_code": 15000,
                "config": "{ \"gpsSpeedCol\": true, \"speedCol\": true, \"kmCol\": true, \"doorStatusCol\": true, \"airConditionStatusCol\": true, \"engineStatusCol\": true, \"latLngCol\": true }",
                "leader": {
                    "id": 31,
                    "username": null,
                    "full_name": "NTAnh",
                    "email": "aa12@gmail.com",
                    "phone": "0987654666",
                    "citizen_id": 86,
                    "address": null,
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-11T09:55:46.702Z",
                    "updated_at": "2024-11-11T10:37:43.000Z"
                },
                "business": {
                    "id": 2,
                    "title": "Kinh doanh vận tải hàng hóa siêu trường, siêu trọng",
                    "created_at": "2023-11-17T15:53:55.048Z",
                    "updated_at": "2023-11-17T15:53:55.048Z"
                }
            },
            {
                "id": 6,
                "name": "TAXI111",
                "status": "active",
                "created_at": "2024-11-13T01:33:09.066Z",
                "updated_at": "2024-11-13T10:14:21.000Z",
                "agency_code": 30001,
                "config": "{ \"gpsSpeedCol\": true, \"speedCol\": true, \"kmCol\": true, \"doorStatusCol\": true, \"airConditionStatusCol\": true, \"engineStatusCol\": true, \"latLngCol\": true }",
                "leader": {
                    "id": 41,
                    "username": "false",
                    "full_name": "true",
                    "email": "123abc@gmail.com",
                    "phone": "0333333322",
                    "citizen_id": 12,
                    "address": "true",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-13T09:38:47.150Z",
                    "updated_at": "2024-11-13T09:51:04.000Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
            {
                "id": 7,
                "name": "TAXI NEW",
                "status": "active",
                "created_at": "2024-11-14T10:17:46.999Z",
                "updated_at": "2024-11-14T10:17:46.999Z",
                "agency_code": 30002,
                "config": null,
                "leader": {
                    "id": 50,
                    "username": null,
                    "full_name": "NTAnh",
                    "email": "aabb123@gmail.com",
                    "phone": "0987622222",
                    "citizen_id": 17,
                    "address": "Hải Dương",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-14T10:17:46.991Z",
                    "updated_at": "2024-11-14T10:17:46.991Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
            {
                "id": 9,
                "name": "TAXI NEW 123",
                "status": "active",
                "created_at": "2024-11-15T03:18:20.996Z",
                "updated_at": "2024-11-15T03:18:20.996Z",
                "agency_code": 30003,
                "config": null,
                "leader": {
                    "id": 52,
                    "username": null,
                    "full_name": "NTAnh",
                    "email": "aabb1234@gmail.com",
                    "phone": "0987222222",
                    "citizen_id": "30",
                    "address": "Hải Dương",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-15T03:18:20.992Z",
                    "updated_at": "2024-11-15T03:18:20.992Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
            {
                "id": 10,
                "name": "TAXI NEW 12345",
                "status": "active",
                "created_at": "2024-11-15T03:18:20.996Z",
                "updated_at": "2024-11-15T03:18:20.996Z",
                "agency_code": 30003,
                "config": null,
                "leader": {
                    "id": 53,
                    "username": null,
                    "full_name": "NTAnh",
                    "email": "aabb1234@gmail.com",
                    "phone": "0987222222",
                    "citizen_id": "30",
                    "address": "Hải Dương",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-15T03:18:20.992Z",
                    "updated_at": "2024-11-15T03:18:20.992Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
            {
                "id": 11,
                "name": "TAXI NEW 12345",
                "status": "active",
                "created_at": "2024-11-15T03:18:20.996Z",
                "updated_at": "2024-11-15T03:18:20.996Z",
                "agency_code": 30003,
                "config": null,
                "leader": {
                    "id": 55,
                    "username": null,
                    "full_name": "NTAnh",
                    "email": "aabb1234@gmail.com",
                    "phone": "0987222222",
                    "citizen_id": "30",
                    "address": "Hải Dương",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-15T03:18:20.992Z",
                    "updated_at": "2024-11-15T03:18:20.992Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
            {
                "id": 12,
                "name": "TAXIuuuu NEW 12345",
                "status": "active",
                "created_at": "2024-11-15T03:18:20.996Z",
                "updated_at": "2024-11-15T03:18:20.996Z",
                "agency_code": 30003,
                "config": null,
                "leader": {
                    "id": 58,
                    "username": null,
                    "full_name": "NTAnh",
                    "email": "aabb1234@gmail.com",
                    "phone": "0987222222",
                    "citizen_id": "30",
                    "address": "Hải Dương",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-15T03:18:20.992Z",
                    "updated_at": "2024-11-15T03:18:20.992Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
            {
                "id": 13,
                "name": "TAXIiiii NEW 12345",
                "status": "active",
                "created_at": "2024-11-15T03:18:20.996Z",
                "updated_at": "2024-11-15T03:18:20.996Z",
                "agency_code": 30003,
                "config": null,
                "leader": {
                    "id": 54,
                    "username": null,
                    "full_name": "NTAnh",
                    "email": "aabb1234@gmail.com",
                    "phone": "0987222222",
                    "citizen_id": "30",
                    "address": "Hải Dương",
                    "dob": null,
                    "avatar": null,
                    "status": 1,
                    "created_at": "2024-11-15T03:18:20.992Z",
                    "updated_at": "2024-11-15T03:18:20.992Z"
                },
                "business": {
                    "id": 6,
                    "title": "Kinh doanh vận tải hành khách bằng xe buýt ",
                    "created_at": "2023-11-17T15:54:50.882Z",
                    "updated_at": "2023-11-17T15:54:50.882Z"
                }
            },
        ]