import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Chip,
  CircularProgress,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';

import { messageToastType_const, STATUS_API } from 'src/app/constant/config';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { getListAgencies, updateWarehouseTransfer } from 'src/features/agencySlice';
import { createSale } from 'src/features/saleSlice';
import { Agency } from 'src/app/model/Agency';


function WarehouseTransferForm({ closeRef, agencyValues }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const error = useSelector(state => state.agencySlice.errors);
  const statusTransfer = useSelector(state => state.agencySlice.statusTransfer);
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);

  const [agencySelected, setAgencySelected] = useState(0);

 const [initValue, setInitValue] = useState({});

 useEffect(() => {
    if(agencyValues){
        setInitValue({id:agencyValues.id});
    }
 }, []);

  const handleSubmit = (data) => {
    console.log('data', data);
    if(data && data?.id){
        dispatch(updateWarehouseTransfer(
            {
            "from_agency_id": data.id,
            "to_agency_id": agencySelected,
            }
        ))
    }
    closeRef();
  };

  const handleChoose = (e) => {
    setAgencySelected(Number(e.target.value))
  };

  useEffect(() => {
    dispatch(getListAgencies());
  }, []);

  console.log('listAgencies ===>',listAgencies);

  return (
    <div>
      <Typography
        style={{
            paddingBottom: '25px',
            color: '#475461',
        }}
      >
        Chuyển tất cả các thiết bị của đại lý này tới đại lý mới
      </Typography>
      <Formik
        initialValues={initValue}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          id: Yup.number()
            .max(100)
            .required('Tên đại lý không được để trống'),
        })}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <InputLabel>Chọn đại lý nhận <span className="text-danger">*</span></InputLabel>
                <TextField
                  className="input-no-fieldset"
                  size="small"
                  fullWidth
                  select
                  helperText={touched.business_id && errors.business_id}
                  margin="normal"
                  name="id"
                  onBlur={handleBlur}
                  onChange={(e) => handleChoose(e)}
                  SelectProps={{
                    native: true
                  }}
                  value={agencySelected}
                  variant="outlined"
                >
                  <option>Đại lý nhận</option>
                  {listAgencies && listAgencies.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              
            </Grid>
            <Box my={3}>
              <Box 
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '15px',
                    '& .left-button': {
                    display: 'flex',
                    }
                }}
              >
                
                  <Button
                    style={{
                      marginRight: '10px',
                      textTranform: 'none !important'
                    }}
                    onClick={() => closeRef()}
                    className="btn-main btn-plain mx-3"
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    Thoát
                  </Button>
                  <Button
                    style={{
                      marginRight: '10px',
                      textTranform: 'none !important'
                    }}
                    className="btn-main mx-3"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Chuyển kho
                  </Button>
                  {statusTransfer === STATUS_API.PENDING && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  formHeader: {
    padding: theme.spacing(3)
  },
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  avatar: {
    height: 100,
    width: 100
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  disableForm: {
    pointerEvents: 'none'
  },
  colorWhite: {
    color: '#fff'
  }
}));

export default WarehouseTransferForm;
