import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Popover,
  Typography,
  List,
  ListItem,
  Checkbox,
  ButtonBase
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogConfirm from 'src/app/components/DialogConfirm';
import { closeDialogConfirm, showDialogConfirm } from 'src/features/uiSlice';
import { MESSAGE } from 'src/app/constant/message';

import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import LoadingComponent from 'src/app/components/Loading';
import { useDispatch } from 'react-redux';
import ModalEditAgency from './edit';
import { AGENCY_STATUS } from 'src/app/constant/config';

import moment from 'moment';
import { deleteAgency } from 'src/features/agencySlice';
import { listAgencies } from './mockData';
import WarehouseTransfer from './warehouseTransfer';


const Results = ({
  className,
  listAgencies,
  getListAgenciesRef,
  actionDetailsUserRef,
  totalAgency,
  actionDeleteUserRef,
  ...rest
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.agencySlice.isLoading);
  const totalAgencies = useSelector(state => state.agencySlice.totalAgencies);
  const classes = useStyles();
  const [limit, setLimit] = useState(PAGE_SIZE_LIST);
  const [page, setPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState();
  const [itemCurrent, setItemCurrent] = useState();
  const [rowsSelected, setRowsSelected] = useState([]);

  const [params, setParams] = useState({
    keyword: '',
    page: page,
    page_size: limit
  });

  // console.log('listAgencies ===>', listAgencies);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickMenu = (event, agency) => {
    setAnchorEl(event.currentTarget);
    setItemCurrent({
      id: agency.id,
      name: agency.name,
      email: agency.leader?.email,
      address: agency.leader?.address,
      phone: agency.leader?.phone,
      business_id: agency.business?.id,
      province_id: agency.leader?.citizen_id,
      status: agency.leader?.status
    });

    setSelectedItem(agency.id);
  };
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleEditAgency = () => {
    setOpenEditModal(true);
    handleCloseMenu();
  };

  const handleWarehouseTransfer = () => {
    setOpenTransferModal(true);
    handleCloseMenu();
  }

  const closeModalEdit = () => {
    setOpenEditModal(false);
  };
  const closeModalTransfer = () => {
    setOpenTransferModal(false);
  };

  const handleDeleteSale = () => {
    dispatch(showDialogConfirm());

    handleCloseMenu();
  };

  const confirmDelete = () => {
    dispatch(deleteAgency({ id: selectedItem }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    if (!getListAgenciesRef) return;
    const newParams = Object.assign({}, params, { page: newPage });
    setParams(newParams);
    getListAgenciesRef(newParams);
  };

  const onEditUser = (type, user) => {
    if (!actionDetailsUserRef) return;
    const sendData = { type: type, data: user };
    actionDetailsUserRef(sendData);
  };

  const checked = id => {
    return rowsSelected.indexOf(id) !== -1;
  };
  const select = driver => {
    if (rowsSelected.indexOf(driver) === -1) {
      const newSelected = [driver, ...rowsSelected];
      setRowsSelected(newSelected);
    } else {
      const newSelected = rowsSelected.filter(i => i !== driver);
      setRowsSelected(newSelected);
    }
  };

  const checkAll = () => {
    if (rowsSelected.length > 0) {
      setRowsSelected([]);
    }
    if (
      listAgencies.length > 0 &&
      rowsSelected.length !== listAgencies.length
    ) {
      const all = listAgencies.map(item => {
        return item.id;
      });
      setRowsSelected(all);
    }
  };

  return (
    <>
      <ModalEditAgency
        open={openEditModal}
        initialValues={itemCurrent}
        closeRef={closeModalEdit}
      />

      <WarehouseTransfer
        open={openTransferModal}
        agencyValues={itemCurrent}
        closeRef={closeModalTransfer}
      />

      <DialogConfirm
        title={MESSAGE.CONFIRM_DELETE_AGENCY}
        textOk={MESSAGE.BTN_YES}
        textCancel={MESSAGE.BTN_CANCEL}
        callbackOk={() => confirmDelete()}
        callbackCancel={() => dispatch(closeDialogConfirm())}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        {isLoading ? (
          <LoadingComponent />
        ) : (
          <div>
            <PerfectScrollbar>
              {listAgencies && listAgencies.length ? (
                <Box minWidth={1050}>
                  <Table>
                    <TableHead>
                      <TableRow>
                          {/* {rowsSelected.length > 0 && (
                            <TableCell width={50} height={48}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                                style={{
                                  position: 'absolute',
                                  width: '100%',
                                  height: '0px',
                                  backgroundColor: '#fff',
                                  zIndex: 2,
                                  padding: '2px 0px'
                                }}
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  height="100%"
                                >
                                  <Checkbox
                                    indeterminate
                                    // onClick={(e) => onCancelSelection(e)}
                                    style={{
                                      svg: { color: '#8F0A0C', fontSize: '21px' }
                                    }}
                                  />
                                  <Typography variant="subtitle2">
                                    Đang chọn ({rowsSelected.length})
                                  </Typography>
                                </Box>
                                <Box display={'flex'} alignItems="center">
                                  <ButtonBase
                                    style={{ color: '#52535C', marginRight: '32px' }}
                                    // startIcon={<ImageIcon image={ShopIcon} />}
                                    // onClick={() => handelMove([...selection])}
                                  >
                                    Chuyển
                                  </ButtonBase>
                                  <ButtonBase
                                    style={{ color: '#E5401C', marginRight: '32px' }}
                                    // startIcon={<ImageIcon image={BackIcon} />}
                                    // onClick={() => handleRecall(selection, true)}
                                  >
                                    Thu hồi
                                  </ButtonBase>
                                  <ButtonBase
                                    style={{ color: '#E5401C', marginRight: '32px' }}
                                    // startIcon={<ImageIcon image={DeleteIcon} />}
                                    // onClick={() => handleDeleteNodes(selection, true)}
                                  >
                                    Xóa
                                  </ButtonBase>
                                </Box>
                              </Box>
                            </TableCell>
                          )} */}
                        <TableCell  width={50}>
                          <Checkbox
                            checked={rowsSelected.length > 0}
                            onChange={() => checkAll()}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        </TableCell>
                        <TableCell>Tên đại lý</TableCell>
                        <TableCell>Số điện thoại</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Địa chỉ</TableCell>
                        <TableCell>Tỉnh thành</TableCell>
                        <TableCell>Loại đại lý</TableCell>
                        <TableCell>Đã kích hoạt</TableCell>
                        <TableCell>Thiết bị</TableCell>
                        <TableCell>Mật khẩu</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listAgencies.map((agency, index) => (
                        <TableRow hover key={agency.id}>
                          <TableCell
                            style={{ maxWidth: '20px' }}
                            onClick={() => select(agency?.id)}
                          >
                            <Checkbox
                              checked={checked(agency.id)}
                              onChange={() => select(agency.id)}
                              color="primary"
                              inputProps={{
                                'aria-label': 'secondary checkbox'
                              }}
                            />
                          </TableCell>
                          <TableCell>{agency.name || '-'}</TableCell>
                          <TableCell>{agency.leader?.phone || '-'}</TableCell>
                          <TableCell>{agency.leader?.email || '-'}</TableCell>
                          <TableCell>{agency.leader?.address || '-'}</TableCell>
                          <TableCell>
                            {agency.leader?.citizen_id || '-'}
                          </TableCell>
                          <TableCell>{agency.business?.title || '-'}</TableCell>
                          <TableCell>{agency?.agency_code || '-'}</TableCell>
                          <TableCell>{agency?.agency_code || '-'}</TableCell>
                          <TableCell>{agency.password || '-'}</TableCell>
                          {/* <TableCell>{moment(agency.created_at).format('DD-MM-yyyy')}</TableCell> */}

                          <TableCell>
                            <span style={{ color: '#AEB1C5' }}>
                              <MoreVertIcon
                                aria-describedby={`menu-device-${agency.id}`}
                                className={`cursor-pointer hover-red`}
                                onClick={e => handleClickMenu(e, agency)}
                              />
                            </span>

                            <Popover
                              id={`menu-device-${agency.id}`}
                              className="popover-device"
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleCloseMenu}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                              }}
                            >
                              <Typography>
                                <List>
                                  <ListItem className="border-bottom list-menu-device cursor-pointer">
                                    Đổi mật khẩu
                                  </ListItem>
                                  <ListItem
                                    onClick={handleEditAgency}
                                    className="border-bottom list-menu-device cursor-pointer"
                                  >
                                    Chỉnh sửa
                                  </ListItem>
                                  <ListItem 
                                    onClick={() => handleWarehouseTransfer()}
                                    className="border-bottom list-menu-device cursor-pointer"
                                    >
                                    Chuyển kho
                                  </ListItem>
                                  <ListItem
                                    onClick={handleDeleteSale}
                                    className="cursor-pointer list-menu-device"
                                  >
                                    <span class="text-danger"> Xóa </span>
                                  </ListItem>
                                </List>
                              </Typography>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <div className="text-center pb-5">
                  <img src="/static/empty.png" class="justify-content-center" />
                  <h4> Danh sách trống </h4>
                </div>
              )}
            </PerfectScrollbar>
          </div>
        )}
      </Card>
      {listAgencies && listAgencies.length > 0 && (
        <div class="mt-3 mr-4 float-right">
          <Pagination
            count={Math.ceil(totalAgencies / limit)}
            style={{ color: '#C62222' }}
            size="small"
            onChange={handlePageChange}
            page={Number(params.page)}
            showFirstButton
            showLastButton
          />
        </div>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  listAgencies: PropTypes.array.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  groupAction: {
    display: 'flex',
    alignItems: 'center',
    '& .action': {
      cursor: 'pointer',
      padding: '0 5px',
      '&:hover': {
        color: '#3f51b5'
      }
    }
  },
  minWithColumn: {
    minWidth: '150px'
  }
}));

export default React.memo(Results);
