import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Card, makeStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookie from 'js-cookie';
import MenuControl from './MenuControl';

const MonitorView = () =>{

  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    if (!Cookie.get('access-token')) navigate('/login');
  }, [navigate]);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <div>
        <MenuControl/>
      </div>
      <div style={{flex: 1, overflowY:'scroll' }}>
        <Outlet />   
      </div>
    </div>
  );
}


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    height: 'calc(100vh - 66px)',
    overflow: 'hidden',
  },
}));


export default MonitorView;
