import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  Chip,
  CircularProgress
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';
import { useNavigate } from 'react-router-dom';

import {
  messageToastType_const,
  STATUS_API
} from 'src/app/constant/config';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { getListProvinces } from 'src/features/provinceSlice';
import { createAgency, getListAgencies, getListAgencyBusiness, updateAgency } from 'src/features/agencySlice';
import { Agency } from 'src/app/model/Agency';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CreateAgencyForm({ closeRef, initialValues }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector(state => state.agencySlice.errors);
  const statusCreate = useSelector(state => state.agencySlice.statusCreate);
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const listProvinces = useSelector(state => state.provinceSlice.listProvinces);
  const listAgencyBusiness = useSelector(state => state.agencySlice.listAgencyBusiness);

  const [listAgenciesSelect, setListAgenciesSelect] = useState([]);
  const [agencyBusinessSelected, setAgencyBusinessSelected] = useState(0);
  const [provinceSelected, setProvinceSelected] = useState(0);

  const [initValue, setInitValue] = useState(initialValues || new Agency());

  console.log('initialValues===>',initialValues);


  useEffect(() => {
    if(initialValues?.province_id){
      setProvinceSelected(+initialValues.province_id);
    }

    if(initialValues?.business_id){
      setAgencyBusinessSelected(+initialValues.business_id);
    }

  }, [initialValues]);

  const handleSubmit = dataUpdate => {
    const newAgency = {
      data:{
        full_name: "null",
        agency_name: dataUpdate.name,
        email: dataUpdate.email,
        phone: dataUpdate.phone,
        address: dataUpdate.address,
        province_id: provinceSelected,
        business_id: agencyBusinessSelected,
      },
      id: initialValues.id,
    }
    dispatch(updateAgency(newAgency));
    closeRef();
  };

  const handleChoose = (e, type) => {
    if (type === 'business_type') {
      setAgencyBusinessSelected(Number(e.target.value));
    }
    if (type === 'province_type') {
      setProvinceSelected(Number(e.target.value));
    }
  };

  useEffect(() => {
    dispatch(getListProvinces());
    dispatch(getListAgencies());
    dispatch(getListAgencyBusiness());
  }, []);

  return (
    <div>
      <Formik
        initialValues={initValue}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          name: Yup.string()
            .max(100)
            .required('Tên đại lý không được để trống'),
          email: Yup.string()
            .email('Email chưa đúng định dạng')
            .required('email không được để trống'),
          phone: Yup.string()
            .matches(
              /^(84|0[3|5|7|8|9])+([0-9]{8})$/,
              'Số điện thoại chưa đúng định dạng'
            )
            .required('Số điện thoại không được để trống'),
          address: Yup.string()
              .max(100)
              .required('Địa chỉ không được để trống'),
        })}
        onSubmit={handleSubmit}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <InputLabel>Tên đại lý <span style={{ color: 'red' }}>*</span></InputLabel>
                <TextField
                  error={Boolean(
                    touched.agency_name && errors.agency_name
                  )}
                  className="input-no-fieldset"
                  fullWidth
                  helperText={touched.agency_name && errors.agency_name}
                  margin="normal"
                  name="name"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <InputLabel>Số điện thoại <span style={{ color: 'red' }}>*</span></InputLabel>
                <TextField
                  error={Boolean(touched.phone && errors.phone)}
                  fullWidth
                  className="input-no-fieldset"
                  helperText={touched.phone && errors.phone}
                  margin="normal"
                  size="small"
                  name="phone"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.phone}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>
              
              <Grid item md={6} xs={12}>
                <InputLabel>Địa chỉ <span style={{ color: 'red' }}>*</span></InputLabel>
                <TextField
                  error={Boolean(
                    touched.address && errors.address
                  )}
                  className="input-no-fieldset"
                  fullWidth
                  helperText={touched.address && errors.address}
                  margin="normal"
                  name="address"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.address}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={6}>
                <InputLabel>Tỉnh thành <span className="text-danger">*</span></InputLabel>
                <TextField
                  className="input-no-fieldset"
                  size="small"
                  fullWidth
                  select
                  helperText={touched.province_id && errors.province_id}
                  margin="normal"
                  name="province_id"
                  onBlur={handleBlur}
                  onChange={(e) => handleChoose(e, 'province_type')}
                  SelectProps={{
                    native: true
                  }}
                  value={provinceSelected || 1}
                  variant="outlined"
                >
                  <option>Chọn tỉnh thành</option>
                  {listProvinces && listProvinces.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <InputLabel>Email <span style={{ color: 'red' }}>*</span></InputLabel>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  margin="normal"
                  className="input-no-fieldset"
                  name="email"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>{' '}
             
              <Grid item md={6} xs={6}>
                <InputLabel>Loại đại lý <span className="text-danger">*</span></InputLabel>
                <TextField
                  className="input-no-fieldset"
                  size="small"
                  fullWidth
                  select
                  helperText={touched.business_id && errors.business_id}
                  margin="normal"
                  name="business_id"
                  onBlur={handleBlur}
                  onChange={(e) => handleChoose(e, 'business_type')}
                  SelectProps={{
                    native: true
                  }}
                  value={agencyBusinessSelected || 0}
                  variant="outlined"
                >
                  <option>Chọn loại đại lý</option>
                  {listAgencyBusiness && listAgencyBusiness.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.title}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Box my={3}>
              <div className={classes.groupButtonSubmit}>
                <div className={classes.wrapper}>
                  <Button
                    style={{ marginRight: '10px', textTranform: 'none !important' }}
                    onClick={() => closeRef()}
                    className="btn-main btn-plain mx-3"
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    Thoát
                  </Button>
                  <Button
                    style={{ marginRight: '10px', textTranform: 'none !important' }}
                    className="btn-main mx-3"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Thêm mới
                  </Button>
                  {statusCreate === STATUS_API.PENDING && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            </Box>
          </form>
        )}
      </Formik>

      
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative'
  },
  shadowBox: {
    boxShadow: '0 2px 5px rgba(0,0,0,.18)'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  formHeader: {
    padding: theme.spacing(3)
  },
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  groupButtonSubmit: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',

    '& .left-button': {
      display: 'flex'
    }
  },
  avatar: {
    height: 100,
    width: 100
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  wrapper: {
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  disableForm: {
    pointerEvents: 'none'
  },
  colorWhite: {
    color: '#fff'
  }
}));

export default React.memo(CreateAgencyForm);
