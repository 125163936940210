export const listVideosMock = [
    {
        FileName:'Video 1',
        FileSize: '00:06:32'
    },
    {
        FileName:'Video 2',
        FileSize: '00:07:32'
    },
    {
        FileName:'Video 3',
        FileSize: '00:08:32'
    },
    {
        FileName:'Video 4',
        FileSize: '00:09:32'
    },
    {
        FileName:'Video 5',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 6',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 7',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 8',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 9',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 10',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 11',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
    {
        FileName:'Video 12',
        FileSize: '00:10:32'
    },
]