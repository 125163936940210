import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField,
  CircularProgress
} from '@material-ui/core';

import { green } from '@material-ui/core/colors';
import { useNavigate } from 'react-router-dom';

import {
  messageToastType_const,
  STATUS_API
} from 'src/app/constant/config';

import * as Yup from 'yup';
import { Formik } from 'formik';
import { getListProvinces } from 'src/features/provinceSlice';
import { getListAgencies } from 'src/features/agencySlice';
import { createStaff, getListStaff, resetChange } from 'src/features/staffSlice';

import { Device } from 'src/app/model/Device';
import { deviceType } from 'src/app/constant/deviceType';
import moment from 'moment';
import { createDevice } from 'src/features/deviceSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateDeviceForm = ({closeRef}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector(state => state.staffSlice.errors);
  const statusCreate = useSelector(state => state.staffSlice.statusCreate);
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
 
  const [deviceTypeSelected, setDeviceTypeSelected] = useState(0);
  const [initValue, setInitValue] = useState(new  Device());

  const handleSubmit = data => {

    const newDevice = {
      ...data,
      date_of_manufacture: moment(data?.date_of_manufacture).format('yyyy-MM-DD'),
      device_type_id: deviceTypeSelected
    }
    console.log('newDevice===>',newDevice);
    setInitValue(newDevice);
    dispatch(createDevice(newDevice));
    closeRef();
  };


  const handleChoose = (e) => {
    setDeviceTypeSelected(Number(e.target.value))
  }

  useEffect(() => {
    dispatch(getListProvinces());
    dispatch(getListAgencies());
  }, [dispatch]);

  return (
    <div>
      <Formik
        initialValues={initValue}
        validationSchema={Yup.object().shape({
          serial: Yup.string()
            .max(100)
            .required('Serial không được để trống'),
          device_type_id: Yup.number()
            .max(100)
            .required('Loại thiết bị không được để trống'),
          hardware_version: Yup.string()
              .max(100)
              .required('Phiên bản không được để trống'),
          date_of_manufacture: Yup.string()
              .max(100)
              .required('Ngày sản xuất không được để trống'),
        })}
        onSubmit={handleSubmit}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values
          }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <InputLabel>Serial <span style={{ color: 'red' }}>*</span></InputLabel>
                <TextField
                  error={Boolean(
                    touched.serial && errors.serial
                  )}
                  className="input-no-fieldset"
                  fullWidth
                  helperText={touched.serial && errors.serial}
                  margin="normal"
                  name="serial"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.serial}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>
              
             
              <Grid item md={6} xs={6}>
                <InputLabel>Loại thiết bị <span className="text-danger">*</span></InputLabel>
                <TextField
                  className="input-no-fieldset"
                  size="small"
                  fullWidth
                  select
                  helperText={touched.device_type_id && errors.device_type_id}
                  margin="normal"
                  name="device_type_id"
                  onBlur={handleBlur}
                  onChange={handleChoose}
                  SelectProps={{
                    native: true
                  }}
                  value={deviceTypeSelected}
                  variant="outlined"
                >
                  <option>Chọn loại thiết bị</option>
                  {deviceType && deviceType.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Grid item md={6} xs={12}>
                <InputLabel>Phiên bản <span style={{ color: 'red' }}>*</span></InputLabel>
                <TextField
                  error={Boolean(
                    touched.hardware_version && errors.hardware_version
                  )}
                  className="input-no-fieldset"
                  fullWidth
                  helperText={touched.hardware_version && errors.hardware_version}
                  margin="normal"
                  name="hardware_version"
                  size="small"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.hardware_version}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        
                      </InputAdornment>
                    )
                  }}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <InputLabel>Ngày sản xuất <span style={{ color: 'red' }}>*</span></InputLabel>
                <TextField
                size="small"
                fullWidth
                error={Boolean(
                    touched.hardware_version && errors.hardware_version
                )}
                className="input-no-fieldset"
                margin="normal"
                name="date_of_manufacture"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.date_of_manufacture}
                type="datetime-local"
                variant="outlined"
              />
              </Grid>
              
            </Grid>
            <Box my={3}>
              <div className={classes.groupButtonSubmit}>
                <div className={classes.wrapper}>
                  <Button
                    style={{ marginRight: '10px', textTranform: 'none !important' }}
                    onClick={() => closeRef()}
                    className="btn-main btn-plain mx-3"
                    color="primary"
                    size="large"
                    variant="contained"
                  >
                    Thoát
                  </Button>
                  <Button
                    style={{ marginRight: '10px', textTranform: 'none !important' }}
                    className="btn-main mx-3"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Thêm mới
                  </Button>
                  {statusCreate === STATUS_API.PENDING && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </div>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
    appBar: {
      position: 'relative'
    },
    shadowBox: {
      boxShadow: '0 2px 5px rgba(0,0,0,.18)'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1
    },
    formHeader: {
      padding: theme.spacing(3)
    },
    root: {
      minHeight: '100%',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3)
    },
    groupButtonSubmit: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '15px',
  
      '& .left-button': {
        display: 'flex'
      }
    },
    avatar: {
      height: 100,
      width: 100
    },
    importButton: {
      marginRight: theme.spacing(1)
    },
    wrapper: {
      position: 'relative'
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    disableForm: {
      pointerEvents: 'none'
    },
    colorWhite: {
      color: '#fff'
    }
  }));
 
export default CreateDeviceForm;