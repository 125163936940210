import React from 'react';
import 'date-fns';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Popover,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Divider,
  AppBar,
  Tabs,
  Tab
} from '@material-ui/core';
import './style.css';

import {
  STATUS_API,
  CAR_STATUS,
  PAGE_SIZE_LIST
} from 'src/app/constant/config';
import { useState, useEffect } from 'react';
import MenuVideo from 'src/app/components/camera/MenuVideo';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { GetUserInfo } from 'src/features/authSlice';
import { useLocation, useNavigate } from 'react-router';
import { getListVehicleTracking, getListVehicleTrackingAdmin } from 'src/features/vehicleSlice';
import PlaybackMenuBox from '../../playback/PlaybackMenuBox';
import PlaybackMenu from '../../playback/PlaybackMenu';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const MenuControl = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const [center, setCenter] = useState({});
  const [zoom, setZoom] = useState(13);
  const [dataDriver, setDataDriver] = useState([]);
  const [showMenu, setShowMenu] = useState(true);

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const statusGetAll = useSelector(state => state.vehicleSlice.statusGetAll);
  const listVehicle = useSelector(state => state.vehicleSlice.listVehicleTracking);

  useEffect(() => {
    dispatch(GetUserInfo());
  }, []);

  useEffect(() => {
    dispatch(getListVehicleTrackingAdmin());
  }, []);


  const query = new URLSearchParams(useLocation().search);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);

  const isAgency = dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const isAdmin = dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const agencyID = isAgency ? dataLogin.agency.id : undefined;

  const deviceSerial = query.get('device_serial')
    ? query.get('device_serial')
    : undefined;

  useEffect(() => {
    if (deviceSerial) {
      const vehicle = findVehicle(query.get('device_serial'));
      getVehicle(vehicle);
    }
  }, [listVehicle]);

  const findVehicle = deviceSerial => {
    const device = listVehicle.filter(vehicle => {
      return vehicle.device_serial === deviceSerial;
    });
    return device.length > 0 ? device[0] : null;
  };

  const [params, setParams] = useState({
    id: 1,
    page: 1,
    pageSize: PAGE_SIZE_LIST
  });

  const getVehicle = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      localStorage.setItem('center', `(${vehicle.lat}, ${vehicle.lng})`);
      setCenter({ lat: Number(vehicle.lat), lng: Number(vehicle.lng) }, 15);
      setZoom(15);
    }
  };

  const getDriverInfo = vehicle => {
    const dataDriverClone = _.cloneDeep(dataDriver);
    let isExitIndex = dataDriverClone.findIndex(
      driver => driver.device_serial === vehicle.device_serial
    );
    if (isExitIndex > -1) {
      dataDriverClone[isExitIndex] = vehicle;
      setDataDriver(dataDriverClone);
    } else {
      setDataDriver([...dataDriverClone, vehicle]);
    }
  };

  return (
    <Card
      style={{
        width: '384px',
        height:'calc(100vh - 66px)'
      }}
    >
      <div className={classes.root}>
        <AppBar
          position="static"
          style={{ backgroundColor: '#ffffff', color: '#45485e' }}
        >
          <Tabs
            value={value}
            variant="fullWidth"
            onChange={handleChange}
            centered
            aria-label="simple tabs example"
            style={{
              height: '52px',
              width: '100%'
            }}
            TabIndicatorProps={{
              style: { backgroundColor: 'red' }
            }}
          >
            <Tab
              label="Giám sát trực tiếp"
              {...a11yProps(0)}
              style={{
                textTransform: 'none'
              }}
              onClick={() => navigate('/app/camera/streaming')}
            />
            <Tab
              label="Phát lại video"
              {...a11yProps(1)}
              style={{
                textTransform: 'none'
              }}
              onClick={() => navigate('/app/camera/playback')}
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <MenuVideo
            statusGetAll={statusGetAll}
            getVehicle={getVehicle}
            listVehicle={listVehicle}
            getDriverInfo={getDriverInfo}
          />
        </TabPanel>
        <TabPanel value={value} index={1} >
          <PlaybackMenu
            statusGetAll={statusGetAll}
            // getVehicle={getVehicle}
            listVehicle={listVehicle}
            getDriverInfo={getDriverInfo}
          />
        </TabPanel>
      </div>
    </Card>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    // backgroundColor:'red'
  },
  typography: {
    padding: theme.spacing(2),
    width: '100%',
    height: '100vh'
  },
  listInformation: {
    width: '100%',
    maxWidth: 375,
    borderRadius: 8,
    maxHeight: 469,
    backgroundColor: theme.palette.background.paper
  },
  popoverHeader: {
    textAlign: 'center'
  },
  textInforCar: {
    color: '#0C1132 !important',
    fontSize: '14px !important',
    lineHeight: '19.6px',
    fontWeight: 400,
    padding: '15px 0'
  }
}));

export default MenuControl;
