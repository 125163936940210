import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Container, makeStyles } from '@material-ui/core';
import Page from 'src/app/components/Page';
import './style.css';
import Loading from 'src/app/components/Loading';

import {
  getListVehicleTracking,
  getListVehicleTrackingAdmin
} from 'src/features/vehicleSlice';
import MapOsm2 from './MapOsm2';

const MapView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const listVehicle = useSelector(state => state.vehicleSlice.listVehicleTracking);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title == 'agency';
  const agencyID = isAgency && dataLogin.agency ?  dataLogin.agency.id : undefined;
  const isAdmin = dataLogin && dataLogin.role && dataLogin.role.title === 'admin';

  useEffect(() => {
    if (agencyID) {
      dispatch(getListVehicleTracking(agencyID))
    }
  }, [agencyID]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getListVehicleTrackingAdmin());
    }
  }, [isAdmin]);

  return (
    <Page className={classes.root}>
      <div class="flex">
        <div className={classes.mapWrap}>
          <div>
              {
                <MapOsm2
                  listVehicle={listVehicle}
                  loadingElement={<Loading />}
              />
              }
          </div>
        </div>
      </div>
    </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingLeft: 0,
    paddingRight: 0
  },
  mapWrap: {
    width: '100%',
    height: 'calc(100%-66px)',
    position: 'relative'
  }
}));

export default MapView;
