export const deviceType = [
    {
        id: 1,
        name:'VGPS_CAR4G'
    },
    {
        id: 2,
        name:'VGPS_MOTO4G'
    },
    {
        id: 3,
        name:'VGPS_CAM4G'
    },
    {
        id: 4,
        name:'VGPS_HUM4G'
    },
]