import React, {
    useState,
    useEffect,
  } from 'react';
  import { List, ListItem, Divider, Typography, Box } from '@material-ui/core';
  import 'leaflet-rotatedmarker';
  import { INFOR_CAR } from 'src/app/constant/config';
  import moment from 'moment';
  import './style.css'
  
  export const ContentCarInfo = props => {
    const {
      positionsInfoBox
    } = props;
  
    const renderValueInWindowBox = infor => {
      switch (infor.dataKey) {
        case 'created_at':
          return moment()
            .utc(positionsInfoBox[infor.dataKey])
            .format('DD/MM HH:mm:ss');
  
        case 'speed_gps':
          return `${Math.floor(positionsInfoBox[infor.dataKey])} Km/h`;
  
        case 'engine_status':
  
        case 'air_condition_status':
          return positionsInfoBox[infor.dataKey] ? 'Bật' : 'Tắt';
  
        default:
          return positionsInfoBox[infor.dataKey] ?? '--';
      }
    };
  
    return (
          <Box
            style={{
              width: 400,
              maxHeight: '490px',
              overflowY: 'scroll',
              padding: '0px 10px 10px 10px',
              borderRadius: '7px',
              background: '#ffffff',
              boxShadow:
              '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
              position:'absolute',
              top: 0,
              left: 0,
             
            }}
          >
            <Typography
              color="primary"
              style={{ color: '#C62222', fontSize: '20px', fontWeight: 600 }}
            >
              Thông tin xe:
            </Typography>
            <List
              component="nav"
              style={{ padding: '0', width: '375px !important' }}
            >
              {INFOR_CAR.map((infor, index) => (
                <>
                  <ListItem
                    disableGutters
                    style={{
                      padding: '0',
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      display: 'inline-block'
                    }}
                  >
                    <div style={{ float: 'left' }}>
                    <div
                      style={{display:'flex', alignItems:'center'}}
                    >
                      <img
                        src={`/static/iconSvg/${infor.icon}.svg`}
                        style={{ paddingRight: '5px' }}
                      />
                        <b> {infor.label} </b>
                    </div>
                    </div>
  
                    <div
                      style={{
                        float: 'right',
                        textAlign: 'right',
                        minWidth: '230px',
                        maxWidth: '248px'
                      }}
                    >
                      {renderValueInWindowBox(infor)}
                    </div>
                  </ListItem>
                  <Divider />
                </>
              ))}
            </List>
          </Box>
    );
  };
  