import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import ReactPlayer from 'react-player';
import ReactHlsPlayer from 'react-hls-player';
import { useSelector } from 'react-redux';
import Hls from 'hls.js';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    maxHeight: '78vh',
    paddingBottom: '0',
    position: 'relative',
    width: '100%'
  }
}));

const VideoPlayer = ({ src }) => {
  const videoRef = useRef(null);
  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(videoRef.current);
      console.log('videoRef.current ', videoRef.current);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        console.log('Manifest loaded, starting playback');
        videoRef.current.play();
      });

      hls.on(Hls.Events.ERROR, (event, data) => {
        console.error('HLS.js error:', data);
      });

      hls.on(Hls.Events.FRAG_LOADED, (event, data) => {
        console.log('Segment loaded:', data.frag);
      });

      hls.on(Hls.Events.FRAG_LOADING, (event, data) => {
        console.log('Đang tải đoạn video:', data.frag.url);
      });

      return () => {
        hls.destroy();
      };
    } else if (videoRef.current.canPlayType('application/vnd.apple.mpegurl')) {
      // Dành cho Safari hoặc các trình duyệt hỗ trợ natively HLS
      videoRef.current.src = src;
      videoRef.current.addEventListener('loadedmetadata', () => {
        videoRef.current.play();
      });
    }
  }, [src]);

  return (
    <video ref={videoRef} controls style={{ width: '100%', height: '100%' }} />
  );
};

const PlaybackDialog = ({ open, setOpen, dataPlayback }) => {
  const theme = useTheme();
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const vehicleSelected = useSelector(state => state.vodSlice.vehicleSelected);

  const newFileName = dataPlayback.fileName.replace(/\.mp4$/, '.m3u8');

  const src1 =
    'https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8';
  const src = `https://cdn01.vgps.vn/vgps/hls/box/${vehicleSelected?.channelId}/${dataPlayback.dirName}/${newFileName}`;
  // const src = `https://cdn01.vgps.vn/vgps/hls/box/861245069435427_01/1732603200/30L069999_channel01_20241126_134001.m3u8`;

  return (
    <>
      <Dialog
        //fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.dialogPaper }}
      >
        <Box style={{ position: 'absolute',top:11, right:11, cursor:'pointer'}}>
          <img
            src={`/static/iconSvg/downloadIcon.svg`}
            alt={'download_Icon'}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </Box>
        <VideoPlayer src={src} />
      </Dialog>
    </>
  );
};

export default PlaybackDialog;
