import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';
import Page from 'src/app/components/Page';
import { useDispatch, useSelector } from 'react-redux';

import { _convertObjectToQuery } from 'src/app/utils/apiService';
import moment from 'moment';
import LoadingComponent from 'src/app/components/Loading';
import { GetUserInfo } from 'src/features/authSlice';
import { PAGE_SIZE_IMAGE } from 'src/app/constant/config';
import { Typography } from 'antd';
import NotFoundView from '../errors/NotFoundView';
import Result from './Result';
import { listVideosMock } from './mockData';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VideoPlaybackList = () => {
  const query = useQuery();
  const page = query.get('page');
  const name = query.get('license_plate');
  const start_date = query.get('start_date');
  const end_date = query.get('end_date');

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const roleUser = dataLogin && dataLogin.role?.title;
  const agencyId = dataLogin && dataLogin.agency?.id;
  const isLoading = useSelector(state => state.vodSlice.isLoading);

  const [currentPage, setCurrentPage] = React.useState(
    page ? Number.parseInt(page) : 1
  );

  //----------------- Reload page ---------------------
  const [params, setParams] = useState({
    page: page || 1,
    page_size: PAGE_SIZE_IMAGE,
    license_plate: name || '',
    start_date:
      Math.floor(
        moment(start_date)
          .utcOffset(0)
          .unix()
      ) ||
      moment()
        .subtract(7, 'd')
        .utcOffset(0)
        .unix() ||
      '',
    end_date:
      Math.floor(
        moment(end_date)
          .utcOffset(0)
          .unix()
      ) ||
      moment()
        .subtract(7, 'd')
        .utcOffset(0)
        .unix() ||
      ''
  });

  const agency_id_local = +localStorage.getItem('agency-id');

  const getListVideoWithParams = (data, ChangePage = false, pageNumber) => {
    const paramValue = Object.assign({}, params, data);
    const dataFormatUix = {
      license_plate: data.license_plate,
      start_date:
        Math.floor(
          moment(data.start_date)
            .utcOffset(0)
            .unix()
        ) ||
        moment()
          .subtract(7, 'd')
          .utcOffset(0)
          .unix(),
      end_date:
        Math.floor(
          moment(data.end_date)
            .utcOffset(0)
            .unix()
        ) ||
        moment()
          .utcOffset(0)
          .unix()
    };
    if (ChangePage) {
      paramValue.page = pageNumber;
      setCurrentPage(pageNumber);
    } else {
      // paramValue.page = pageNumber;
      setCurrentPage(paramValue.page);
    }

    setParams(paramValue) ;
    navigate(`/app/camera/playback?${_convertObjectToQuery(paramValue)}`);
    const paramFormattedValue = Object.assign({}, paramValue, dataFormatUix);
    if (roleUser && roleUser === roles.ADMIN) {
      dispatch(AdminGetImages(paramFormattedValue));
    } else if (agency_id_local) {
      dispatch(
        GetImages({ payload: paramFormattedValue, agencyId: agency_id_local })
      );
    } else {
      dispatch(GetImages({ payload: paramFormattedValue, agencyId: agencyId }));
    }
  };

  const clearSearch = () => {
    const paramValue = {
      page: 1,
      page_size: PAGE_SIZE_IMAGE,
      license_plate: ''
    };
    setParams(paramValue);
    if (roleUser && roleUser === roles.ADMIN) {
      dispatch(AdminGetImages(paramValue));
    } else {
      dispatch(GetImages({ payload: paramValue, agencyId }));
    }
  };

  useEffect(() => {
    dispatch(GetUserInfo());
  }, [dispatch]);

    // if (true) {
    //   return (
    //     <Page className={classes.root} title="Video">
    //       <Container maxWidth={false}>
    //         <NotFoundView />
    //       </Container>
    //     </Page>
    //   );
    // }
  return (
   
      <Page className={classes.root}>
        <Container maxWidth={true} >
          <React.Fragment>
            <Typography
              style={{
                width: '100%',
                height: '36px',
                color: '#0C1132',
                display: 'flex',
                alignItems: 'center',
                // paddingLeft: '20px',
                fontWeight: 'bold',
                marginTop: '16px',
                fontSize: '20px'
              }}
            >
              Danh sách video phương tiện
            </Typography>
            <Box mt={3}>
              <React.Fragment>
                {isLoading ? (
                  <LoadingComponent />
                ) : (
                    <Result />
                )}
              </React.Fragment>
            </Box>
          
          </React.Fragment>
        </Container>
      </Page>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(2)
  },
}));

export default VideoPlaybackList;
