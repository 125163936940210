import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ToastMessage from '../../components/ToastMessage';
import { messageToastType_const } from '../../constant/config';
import { showToast } from '../../../features/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, makeStyles, Box, Select, MenuItem } from '@material-ui/core';
import { generate_token } from 'src/app/utils/commomService';

import VGPSPlayer from './VGPSPlayer';
import { forEach } from 'draft-js/lib/DefaultDraftBlockRenderMap';
import './style.css';
import { Typography } from 'antd';

const StyledVideo = styled.video`
  width: 100%;
  height: 100%;
  background-color: #4c4c4c;
  background-clip: content-box;
  border: 1px 1px 0px 1px solid #333333;
  object-fit: fill;
`;
const CDN_URL = process.env.REACT_APP_CDN_WSS_SERVER;

const useStyles = makeStyles({
  root: {
    color:'ffffff',
  },

});

const VideoWall = React.memo(({ deviceSerial }) => {
  const classes = useStyles();
  const [videoMode, setVideoMode] = useState(false);
  
  const cameras = 
  videoMode ?
  [
    //HD
    {
      id: `${deviceSerial}_01`,
      name: 'CH1'
    },
    {
      id: `${deviceSerial}_03`,
      name: 'CH2'
    }
  ]

  :

  [
    //SD
    {
      id: `${deviceSerial}_02`,
      name: 'CH1'
    },
    {
      id: `${deviceSerial}_04`,
      name: 'CH2'
    }
  ]


  
  //const [divCam, setDivCam] = useState(2);
  // const [playCam, setPlayCam] = useState(false);
  //let playCam = false;
  let divCam = 2;
  const Video = props => {
    const ref = useRef();
    return (
      <>
        <div style={{ height: '44vh' }}>
          <StyledVideo playsInline autoPlay ref={ref} id={props.id} controls/>
        </div>
      </>
    );
  };
  var players = [];
  useEffect(() => {
    if (!deviceSerial) return;

    if (players.length > 0) {
      for (let l of players) {
        l.stop();
      }
      players = [];
    }
    for (let c of cameras) {
      if (c.id) {
        let token = generate_token(32);
        var ws_url = `${CDN_URL}/evup/${token}/${c.id}`;
        const player = new VGPSPlayer(c.id, { transport: ws_url });
        players.push(player);
      }
    }

    return () => {
      if (players.length > 0) {
        for (let l of players) {
          l.stop();
        }
        players = [];
      }
    };
  }, [cameras]);
  /*const handleChange = () => {
    const arr = [1, 2, 4];
    const index = arr.findIndex(e => e == divCam);
    if (index < arr.length - 1) {
      //divCam = arr[index + 1];
      setDivCam(arr[index + 1]);
    } else {
      setDivCam(arr[0]);
      //divCam = arr[0];
    }
  };
  const play = () => {
    if(roomID) {
      setPlayCam(true)
      connectByRoom(roomID)
    } else {
      setToastMessage('Vui lòng chọn thiết bị');
      setPlayCam(false)
      dispatch(showToast());
    }
    //var ws_url = `ws://cdn01.vgps.vn/evup/${Date.now()}/${cameraId}`;
    //new VGPSPlayer(cameraId, { transport: ws_url });
    //playCam = true;
    //setPlayCam(true);
  };*/

  /* const stop = () => {
    playCam = false;
    //setPlayCam(false);
    //setPeers([])
  };*/
  return (
    <>
          <>
      {/*<div
        className="row p-1"
        style={{ backgroundColor: '#c62222', margin: '1em 0px' }}
      >
        <div className="col-6 items-left">
          {!playCam ? (
            <img
              className="cursor-pointer"
              onClick={() => play()}
              width="30px"
              height="30px"
              src="/static/iconSvg/controlPlay.svg"
            />
          ) : (
            <img
              className="cursor-pointer"
              onClick={() => stop()}
              width="30px"
              height="30px"
              src="/static/iconSvg/controlStop.svg"
            />
          )}
        </div>
        <div className="col-6 d-flex justify-content-end">
          <img
            style={{
              padding: '0.05px',
              borderRadius: '2px',
              border: '1px solid white'
            }}
            className="cursor-pointer"
            onClick={() => handleChange()}
            width="28px"
            height="28px"
            src={`/static/iconSvg/Grid${divCam}.svg`}
          />
        </div>
          </div>*/}
          
          </>
      <div className="row" 
          style={{
              position: 'relative',
              top: 0,
              left: 0,
          }}
      >
        {cameras.length > 0 &&
          cameras.map((camera, index) => {
            return index < divCam ? (
              <Box key={index} className={'col-12 px-0'}>
                <Box
                  style={{
                    position:'relative',
                    // paddingLeft: 2.5,
                    // paddingRight: 2.5
                    top: 0,
                    left: 0,
                  }}
                >
                  <Typography
                    style={{
                      position: 'absolute',
                      top: '0px',
                      right: '0px',
                      background: '#c62222',
                      color: 'white',
                      padding: '4px',
                      borderRadius: '0px 0px 0px 4px',
                      zIndex: 1
                    }}
                  >
                    {camera.name}
                  </Typography>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Video id={camera.id} />
                  </Box>
                </Box>
              </Box>
            ) : null;
          })}
        {/*<Video key={cameraId} />*/}
        <Box
          style={{
            display: 'flex',
            backgroundColor: '#ad0000',
            height: '49px',
            padding: '0 25px',
            position: 'absolute',
            left: '20',
            bottom: -46,
            width: '100%'
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box style={{ display: 'flex', gap: '20px' }}>
              {/* <Box>
                <img src="/static/iconSvg/speakerIcon.svg" />
              </Box> */}
                <Typography 
                  style={{ 
                    color: '#ffffff' ,
                    cursor: 'pointer',
                    display:'inline-block'
                  }}
                  onClick = {()=>setVideoMode(!videoMode)}
                >
                   {videoMode === true ? 'HD': 'SD'}
                </Typography> 
            </Box>
            <Box style={{ display: 'flex', gap: '15px', cursor:'pointer' }}>
              <Box>
                <img src="/static/iconSvg/grid/grid4_icon.svg" />
              </Box>
              {/* <Box>
                <img src="/static/iconSvg/fullScreenIcon.svg" />
              </Box> */}
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
});

export default VideoWall;
