import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Box } from '@material-ui/core';
import 'react-awesome-lightbox/build/style.css';
import NullData from 'src/app/components/NullData';
import VideoItem from 'src/app/components/VideoItem';
import { useSelector } from 'react-redux';
import PlaybackDialog from './PlaybackDialog';
import { listVideosMock } from './mockData';


const Result = () => {
  const [openVideo, setOpenVideo] = React.useState(false);
  const [dataPlayback, setDataPlayback] = React.useState({
    dirName: '',
    fileName:'',
  });
  
  const actionDetailsVideoRef = (data) => {
    setDataPlayback(data);
    setOpenVideo(true);
  };

  const listVod = useSelector(state => state.vodSlice.listVod);
  
  return (
    listVod && (
      <>
      <PlaybackDialog
        open = {openVideo}
        setOpen = {setOpenVideo}
        dataPlayback = {dataPlayback}
      />

      <Box style={{paddingRight:'30px'}}>
        {listVod?.length === 0 ? (
          <NullData />
        ) : (
          <Grid container spacing={3}>
            {listVod?.map((video, i) => (
              <Grid item key={video.id} lg={4} md={6} xs={12}>
                <VideoItem 
                  actionDetailsVideoRef={actionDetailsVideoRef}
                  video={video}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      </>
    )
  );
}

Result.propTypes = {
    listVideos: PropTypes.array.isRequired,
    actionDetailsImgRef: PropTypes.func
};
  
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
}));
export default Result;
