import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  List,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Truck as Car, MapPin as Map, Users as User } from 'react-feather';
import RouterIcon from '@material-ui/icons/Router';
import NavItem from './NavItem';
import { useSelector } from 'react-redux';
import { roleName_const } from '../../../constant/config';

import { withStyles } from "@material-ui/core/styles";
import { icon } from 'leaflet';
import { ImageIcon } from 'src/app/utils/UltilsComponents';

import { styled } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 280,
    top: 46,
    height: 'calc(100% - 46px)',
    marginTop: '20px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  nested: {
    // paddingLeft: 40
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    color: '#45485E'
  },
  titleHeader: {
    width: '100%',
    height: '45px',
    color: '#C62222',
    display: 'flex',
    alignItems: 'center',
    padding: '15px',
    fontWeight: 'bold',
    fontSize: "18px",
  //  textTransform: 'uppercase',
    marginBottom: '20px'
  }
}));

const NavBarContainer = styled(Box)({
  
});

const menuTT073 = [
  {
    href: `/app/baocao-bgt/tt073/hanh-trinh-chay-xe`,
    title: 'Hành trình chạy xe',
    icon:  <ImageIcon image={'/static/images/nav-hanhtrinhchayxe.svg'}/>,
    icon_active:  <ImageIcon image={'/static/images/nav-hanhtrinhchayxe.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt073/toc-do-cua-xe`,
    title: 'Tốc độ của xe',
    icon:  <ImageIcon image={'/static/images/nav-tocdoxe.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt073/qua-toc-do-gioi-han`,
    title: 'Quá tốc độ giới hạn',
    icon:  <ImageIcon image={'/static/images/nav-quatocdogioihan.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt073/thoi-gian-lai-xe-lien-tuc`,
    title: 'Thời gian lái xe liên tục',
    icon:  <ImageIcon image={'/static/images/nav-thoigianlaixelientuc.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt073/bao-cao-tong-hop-theo-xe`,
    title: 'Báo cáo tổng hợp theo xe',
    icon:  <ImageIcon image={'/static/images/nav-baocaotonghopxe.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt073/bao-cao-tong-hop-theo-lai-xe`,
    title: 'Báo cáo tổng hợp theo lái xe',
    icon:  <ImageIcon image={'/static/images/nav-baocaotonghoplaixe.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt073/bao-cao-dung-do`,
    title: 'Báo cáo dừng đỗ',
    icon:  <ImageIcon image={'/static/images/nav-baocaodungdo.svg'}/>,
  },
] 

const menuTT09 = [
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-5`,
    title: 'Tổng hợp vi phạm',
    icon:  <ImageIcon image={'/static/images/nav-baocaotonghopvipham.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-8`,
    title: 'Thống kê vi phạm về tốc độ',
    icon:  <ImageIcon image={'/static/images/nav-quatocdogioihan.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-14`,
    title: 'Chi tiết vi phạm tốc độ xe chạy',
    icon:  <ImageIcon image={'/static/images/nav-baocaoviphamtocdo.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-16`,
    title: 'Báo cáo lái xe quá 4h',
    icon:  <ImageIcon image={'/static/images/nav-baocaolaixequa4h.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-17`,
    title: 'Báo cáo vi phạm thời gian lái xe liên tục trong ngày (10 giờ)',
    icon:  <ImageIcon image={'/static/images/nav-thoigianlaixelientuc.svg'}/>,
  },
  {
    href: `/app/baocao-bgt/tt09/tt09-phu-luc-19`,
    title: 'Chi tiết vi phạm truyền dữ liệu',
    icon:  <ImageIcon image={'/static/images/nav-baocaotonghopxe.svg'}/>,
  },
] 

// makeStyles-button-1587 : dropdown button

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  // const location = useLocation();
  // const dataLogin = useSelector(state => state.authSlice.dataLogin);

  const content = (
    <NavBarContainer 
      height="100%" 
      display="flex" 
      flexDirection="column" 
    >
      <Box style={{paddingTop:'16px'}}>
        <List >
            <Typography className={classes.titleHeader}>Báo cáo BGTVT</Typography>
            <NavItem 
              title="Báo cáo TT073/2014/TT-BGTVT" 
              arrayNested={menuTT073}
              
            />

            <NavItem 
              title="Báo cáo TT09/2014/TT-BGTVT" 
              arrayNested={menuTT09}
            />
        </List>
      </Box>
      <Box flexGrow={1} />
    </NavBarContainer>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
