import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import './style.css';
import moment from 'moment';
import { STATUS_API } from 'src/app/constant/config';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import VehicleTablecell from './VehicleTablecell';
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    },
    cursor: 'pointer'
  }
}))(TableRow);

const VehicleTable = ({ vehicles, getVehicle, carStatus, deviceType }) => {
  const navigate = useNavigate();
  const [rowActive, setRowActive] = useState(null);
  const statusGetListTracking = useSelector(state => state.vehicleSlice.statusGetListTracking)
  const [allVehicles, setAllVehicles] = useState(vehicles);
  const listVehicleTracking = useSelector(state => state.vehicleSlice.listVehicleTracking);
  const listVehicle = useSelector(state => state.vehicleSlice.listVehicle);

  const statisticVehicleTracking = useSelector(state => state.vehicleSlice.statisticVehicleTracking);
  
  useEffect(() => {
    setAllVehicles(vehicles);
  }, [vehicles])

  const handleRowClick = vehicle => {
    setRowActive(vehicle.device_id)
    getVehicle(vehicle);
  };

  const renderVehicle = (listVehicle, listVehicleTracking) => {
    if (listVehicle === null)   
      return listVehicleTracking
    return listVehicle
  };

  const listVehicleMonitor = renderVehicle(listVehicle, listVehicleTracking);
  
  const renderBody = listVehicleMonitor?.length > 0 ? listVehicleMonitor?.map((row, index) => {
    if (carStatus || deviceType) {
      if (carStatus && !deviceType && carStatus === row.device_status) {
        return (
          <TableBody >
            <StyledTableRow
              className={row.device_id === rowActive ? 'row-actived' : ''}
              key={row.vehicle_id ?? row.id ?? index + 1}
              onClick={() => handleRowClick(row)}
            >
               <VehicleTablecell 
                row ={row}
                index ={index}
              />
              <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
              <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

            </StyledTableRow>
          </TableBody >
        )
      }
      if (!carStatus && deviceType && deviceType === row.device_type) {
        return (
          <TableBody >
            <StyledTableRow
              className={row.device_id === rowActive ? 'row-actived' : ''}
              key={row.vehicle_id ?? row.id ?? index + 1}
              onClick={() => handleRowClick(row)}
            >
               <VehicleTablecell 
                row ={row}
                index ={index}
              />
              <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
              <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

            </StyledTableRow>
          </TableBody >
        )
      }
      if (carStatus && deviceType && carStatus === row.device_status && deviceType === row.device_type) {
        return (
          <TableBody >
            <StyledTableRow
              className={row.device_id === rowActive ? 'row-actived' : ''}
              key={row.vehicle_id ?? row.id ?? index + 1}
              onClick={() => handleRowClick(row)}
            >
               <VehicleTablecell 
                row ={row}
                index ={index}
              />
              <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
              <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

            </StyledTableRow>
          </TableBody >
        )
      }
    }
    else {
      return (
        <TableBody >
          <StyledTableRow
            className={row.device_id === rowActive ? 'row-actived' : ''}
            key={row.vehicle_id ?? row.id ?? index + 1}
            onClick={() => handleRowClick(row)}
          >
              <VehicleTablecell 
                row ={row}
                index ={index}
              />
            <TableCell align="center">{Math.floor(row.speed_gps) || 0}</TableCell>
            <TableCell align="center">{row ? moment.unix(row.created_at).format('DD/MM HH:mm:ss') : '--'}</TableCell>

          </StyledTableRow>
        </TableBody >
      )
    }

  }
  ) : <caption> Không có thiết bị </caption>

  return (
    <div >
      <TableContainer component={Paper} className="vehicle_table" style={{height:'550px'}}>
        <Table stickyHeader aria-label="sticky table" >
          <TableHead
            style={{ 
              background: '#C62222 !important', 
              color: 'white !important' 
            }}
          >
            <TableRow>
              <TableCell align="center">Biển số</TableCell>
              <TableCell align="center">Vận tốc (Km/h) </TableCell>
              <TableCell align="center">Thời gian</TableCell>
            </TableRow>
          </TableHead>
          {statusGetListTracking !== STATUS_API.LOADING ? 
            renderBody 
            : 
          <caption >
            <div style={{ textAlign: 'center', padding: 20 }}> <CircularProgress /></div>
          </caption>}
        </Table>
      </TableContainer>
    </div>
  );
};

export default React.memo(VehicleTable);
