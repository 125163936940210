import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { CameraAlt, Room } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },

  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1),
    color: '#C62222'
  },
  imgStyle: {
    cursor: 'pointer',
    width: '100%',
    height: '230px'
  },
  borderRounded: {
    // marginRight: 15,
    textAlign: 'center',
    margin: '0 auto',
    paddingTop: 7,
    width: '60px',
    height: '60px',
    border: '3px solid #C62222',
    borderRadius: '100%'
  },
  groupInforImage: {
    display: 'flex',
    padding: 10,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  sizeTextImage: { fontSize: '16px' }
}));

const VideoItem = ({ actionDetailsVideoRef, video }) => {
  const classes = useStyles();

  const handleView = () => {
    if (!actionDetailsVideoRef) return;
    actionDetailsVideoRef({
        dirName: video.DirName,
        fileName: video.FileName,
    });
  };
  return (
    <Card 
      onClick={() => handleView()}
      style={{
        height: '165px',
        cursor: 'pointer',
        // width: '282px',
        borderRadius: '8px',
        boxShadow: '0 7px 7px 0px rgb(0 0 0 / 7%)',
        position:"relative",
      }}
    >
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center"
        mb={1}
        style={{
          backgroundColor:'#c4c4c4',
          height:'50%',
        }}
      >
          <img
            src={`/static/iconSvg/video_storage.svg`}
            alt={'file_name'}
            style={{
              width: '50%',
              height: '50%'
            }}
          />
      </Box>

   
      <Box style={{ position: 'absolute',top:11, right:12}}>
          <img
            src={`/static/iconSvg/downloadIcon.svg`}
            alt={'download_Icon'}
            style={{
             width:'100%',
             height:'100%'
            }}
          />
        </Box>


      <Grid container style={{
         display: 'flex',
        padding: '0px 20px',
         justifyContent: 'space-between',
         alignItems: 'center'
      }}>
        <Grid item xs={8}>
          <Grid container justify="space-between" spacing={2}>
            <Grid className={classes.statsItem} item>
              {/* <AccessTimeIcon className={classes.statsIcon} /> */}
              <Typography
                className={classes.sizeTextImage}
                style={{color: '#0c1133', fontSize: '16px', fontWeight: '500'}}
                display="inline"
                variant="caption"
              >
                  {video.FileName}
              </Typography>
            </Grid>
          </Grid>{' '}
          <Grid container justify="space-between" spacing={2}>
            <Grid className={classes.statsItem} item>
              {/* <CameraAlt className={classes.statsIcon} /> */}
              <Typography
                className={classes.sizeTextImage}
                style={{color: '#c72222', fontSize: '15px'}}
                display="inline"
                variant="caption"
              >
                {video.FileSize}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

VideoItem.propTypes = {
  actionDetailsVideoRef: PropTypes.func,
  video: PropTypes.shape({
    url: PropTypes.string,
    file_name: PropTypes.string
  }).isRequired
};

export default VideoItem;
