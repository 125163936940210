import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Box,
  FormControl,
  Select,
  Paper,
  Grid,
  TextField,
  SvgIcon,
  InputAdornment
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {
  getListVehicle,
  getListVehicleTracking,
  getVehicleLicensePlateByAdmin,
  setAgencySelected,
  setGetListLoading
} from 'src/features/vehicleSlice';

import { CAR_STATUS, STATUS_API } from 'src/app/constant/config';
import { useSearchParams } from 'react-router-dom';
import { getListDeviceType } from 'src/features/deviceTypeSlice';
import { getListAgencies } from 'src/features/agencySlice';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ToolBar = ({ setVehicleType, vehicleType }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('');

  const [id, setId] = useState(null);
  let i;
  const [urlString, setUrl] = useState(`${id}`);

  const dispatch = useDispatch();
  const listDeviceType = useSelector(
    state => state.deviceTypeSlice.listDeviceType
  );
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);

  const dataLogin = useSelector(state => state.authSlice.dataLogin);

  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  const listLicensePlate = useSelector(
    state => state.vehicleSlice.listLicensePlate
  );

  useEffect(() => {
    dispatch(getListVehicleTracking(urlString));
  }, [id, urlString]);

  useEffect(() => {
    dispatch(getVehicleLicensePlateByAdmin());

    return () => {
      getListVehicle({ license_plate: '' });
    };
  }, []);

  const handleSearchVehicle = e => {
    const newValue = e.target.value;
    setValue(newValue);
    if (isAdmin) {
      dispatch(getListVehicle({ license_plate: newValue }));
    } else if (isAgency) {
      dispatch(
        getListVehicle({ license_plate: newValue, agency_id: agencyID })
      );
    }
  };

  useEffect(() => {
    dispatch(getListDeviceType());
    dispatch(getListAgencies());
  }, []);

  const handleChangeFilterVehicleType = e => {
    // setVehicleType(e.target.value);
  };

  const handleChangeAgency = e => {
    window.clearInterval(i);
    e.preventDefault();
    dispatch(setGetListLoading(STATUS_API.LOADING));
    dispatch(setAgencySelected(e.target.value));
    setId(e.target.value);
    setUrl(e.target.value);
    dispatch(getListVehicleTracking(e.target.value));
  };

  const handleChangeInput = e => {
    e.preventDefault();
    const newValue = e.target.value;
    setValue(newValue);
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const newValue = e.target.value;
      setValue(newValue);
      if (isAdmin) {
        dispatch(getListVehicle({ license_plate: newValue }));
      } else if (agencyID && isAgency) {
        dispatch(getListVehicle({ license_plate: newValue, agency_id: 1 }));
      }
    }
  };

  return (
    <Paper component="form" className={classes.root}>
      <Box style={{ borderRadius: '4px', marginBottom: '10px' }}>
        {/* <IconButton  onClick={(e) => handleSearchVehicle(e)} aria-label="search">
                <SearchIcon />
              </IconButton> */}
        {/* <InputBase
                className={classes.input}
                placeholder="Tìm kiếm phương tiện"
                inputProps={{ 'aria-label': 'search google maps' }}
                value={value || ''}
                onChange={(e) => handleChangeInput (e)}
                onKeyDown={keyPress}
              /> */}
        {listLicensePlate && (
          <Autocomplete
            freeSolo
            id="free-solo-2-demo"
            size="small"
            disableClearable
            options={listLicensePlate}
            // value={value ? value : ''}
            renderInput={params => (
              <TextField
                {...params}
                margin="dense"
                className="input-no-fieldset"
                variant="outlined"
                placeholder="Tìm kiếm phương tiện"
                onChange={e => handleChangeInput(e)}
                onKeyDown={keyPress}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ cursor: 'pointer' }}
                    >
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon onClick={e => handleSearchVehicle(e)} />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        )}

        {/* <Grid container >
            <FormControl  style={{width: '100%'}} size="small">
              <div style={{ display: "flex", flexDirection: "column ", width: "100%", borderColor: "#E5E5E8", borderWidth: "1px", borderStyle: "solid", padding: '0.5em', borderRadius: "3px" }}>
                <Select
                  labelId="demo-simple-select-outlined-label1"
                  id="demo-simple-select-outlined1"
                  value={statusSelected ? statusSelected : ''}
                  size="small"
                  onChange={handleChangeFilterStatus}
                  label="Age"
                  //defaultValue={''}
                  className={classes.selectDropdown}
                  displayEmpty
                  disableUnderline
                >
                  <MenuItem value="">
                    Tất cả trạng thái
                  </MenuItem>
                  {
                      CAR_STATUS.map((car, index) => 
                      <MenuItem value={car.value} key={index++}>{car.label}</MenuItem>
                    )
                  }
                </Select>
              </div>
            </FormControl>
          </Grid> */}

        <Box
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop:'20px',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column ',
              width: '48%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.3em',
              marginBottom: '24px',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={vehicleType ||''}
              onChange={e => handleChangeFilterVehicleType(e)}
              displayEmpty
              disableUnderline
            >
              <MenuItem value="">
                <small> Tất cả thiết bị </small>
              </MenuItem>
              {listDeviceType &&
                listDeviceType.map(item => (
                  <MenuItem value={item.name}>{item.name}</MenuItem>
                ))}
            </Select>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column ',
              width: '48%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.3em',
              marginBottom: '24px',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={''}
              // value={agencyParams || ''}
              onChange={e => handleChangeAgency(e)}
              displayEmpty
              disableUnderline
            >
              <MenuItem value="">
                <small> Chọn đại lý </small>
              </MenuItem>
              {listAgencies &&
                listAgencies.map(agency => (
                  <MenuItem value={agency.id}>{agency.name}</MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 20px 0 20px',
    boxShadow: 'unset'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: '1 1',
    padding: '0 '
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  titleLotrinh: {
    fontSize: '18px',
    color: '#C62222',
    fontWeight: 700,
    marginBottom: '10px'
  },
  titleTime: {
    fontSize: '18px',
    color: '#0C1132',
    fontWeight: 700,
    marginBottom: '10px'
  },
  formControl: {
    width: 360,
    marginBottom: '10px'
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectDropdown: {
    padding: '5 5 5 5 '
  }
}));

export default React.memo(ToolBar);
