import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Grid, makeStyles, Box } from '@material-ui/core';
import './style.css';
import Loading from 'src/app/components/Loading';
import { PAGE_SIZE_LIST } from 'src/app/constant/config';
import { GetUserInfo } from 'src/features/authSlice';
import { getListVehicleTracking, getListVehicleTrackingAdmin } from 'src/features/vehicleSlice';
import MenuVideo from 'src/app/components/camera/MenuVideo';
import VideoComponent from './VideoComponent';
import VideoWall from './VideoWall';
import _ from "lodash"
import MapOsmCamera from './MapOsmCamera';

const MapViewCamera = () => {
  const dispatch = useDispatch();
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAdmin = dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const isAgency = dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const agencyID = isAgency ? dataLogin.agency.id : undefined;

  useEffect(() => {
    dispatch(GetUserInfo());
  }, []);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getListVehicleTrackingAdmin());
    }
  }, [isAdmin]);


  const query = new URLSearchParams(useLocation().search);
  
  let deviceSerial = query.get('device_serial') ? query.get('device_serial') : undefined;

  useEffect(() => {
    if (agencyID) {
      dispatch(getListVehicleTracking(agencyID + '?device_type=VGPS_CAM4G'));
      const i = window.setInterval(() => {
        dispatch(getListVehicleTracking(agencyID + '?device_type=VGPS_CAM4G'));
      }, 10000);
      return () => {
        window.clearInterval(i);
      };
    }
  }, [agencyID]);

  const listVehicle = useSelector(state => state.vehicleSlice.listVehicleTracking);
  const statusGetAll = useSelector(state => state.vehicleSlice.statusGetAll);
  const [params, setParams] = useState({
    id: 1,
    page: 1,
    pageSize: PAGE_SIZE_LIST
  });

  const [roomID, setRoomID] = useState();
  //const [cameraId, setCameraId] = useState();
  const [center, setCenter] = useState({});
  const [zoom, setZoom] = useState(13);

  const [dataDriver, setDataDriver] = useState([]);
  const [mapRef, setMapRef] = useState(null);

  useEffect(() => {
    if (deviceSerial) {
      const vehicle = findVehicle(deviceSerial);
      getVehicleViewMap(vehicle);
    }
  }, [deviceSerial]);


  const findVehicle = deviceSerial => {
    const device = listVehicle.filter(vehicle => {
      return vehicle.device_serial === deviceSerial;
    });

    return device.length > 0 ? device[0] : null;
  };

  const getSetView = (mapRef) => {
    if (mapRef){
      setMapRef(mapRef);
    }
  }

  const getVehicleViewMap = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) { 
      setCenter([Number(vehicle.lat), Number(vehicle.lng)]);
      if(mapRef) return;
      mapRef.setView([Number(vehicle.lat), Number(vehicle.lng)], 15);
    }
  };
 
  const getDriverInfo = vehicle => {
    const dataDriverClone = _.cloneDeep(dataDriver)
    let isExitIndex = dataDriverClone.findIndex(driver => driver.device_serial === vehicle.device_serial)
      if(isExitIndex > -1){    
          dataDriverClone[isExitIndex] = vehicle
          setDataDriver(dataDriverClone)
      }else{
          setDataDriver([...dataDriverClone,vehicle])
      }
  }

  const listVehicles = listVehicle.filter(vehicle => {
    return vehicle.device_serial === query.get('device_serial');
  });

  return (
    <Box
      style={{
        display: 'flex',
        overflowX:'hidden'
        // height: 'calc(100vh - 66px)'
      }}>
        <div className="row">
          <div 
            className="col-6"
            style={{
              paddingRight: 10,
              paddingLeft: 40,
              
            }}
          >
            {/*<VideoComponent roomID={roomID} key={roomID}/>*/}
          
              <VideoWall deviceSerial={deviceSerial} key={deviceSerial} />
            
          </div>
          <div
            className="col-6"
            style={{
              paddingLeft: 2,
              paddingRight: 0,
              
            }}
          >
            {
                <MapOsmCamera
                  center={center}
                  zoom={zoom}
                  getSetView={getSetView}
                  listVehicle={query.get('device_serial') ? listVehicles : listVehicle}
                  loadingElement={<Loading />}
                />
            }
          </div>
        </div>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '80vh',
    paddingTop: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0
  },
  mapWrap: {
    width: '100%',
    height: '90vh ',
    position: 'relative'
  }
}));

export default MapViewCamera;
