export class Agency {
  constructor() {
    this.agency_name = ""
    this.email = ""
    this.password =  ""
    this.confirm_password = ""
    this.phone = ""
    this.full_name = ""
    this.address = ""
    //dvt
    this.province_id = 0
    this.business_id = 0
}
}