import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Drawer,
  Hidden,
  List,
  makeStyles
} from '@material-ui/core';
import { Truck as Car, MapPin as Map, Users as User } from 'react-feather';
import NavItem from './NavItem';
import { useSelector, useDispatch } from 'react-redux';
import { GetUserInfo } from 'src/features/authSlice';
import { ImageIcon } from 'src/app/utils/UltilsComponents';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 280
  },
  desktopDrawer: {
    width: 280,
    top: 46,
    height: 'calc(100% - 46px)',
    marginTop: '20px'
  },
  avatar: {
    cursor: 'pointer',
    width: 46,
    height: 46
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontFamily: 'Helvetica',
    fontSize: '14px',
    color: '#45485E',
    marginBottom: '10px',
  },
  nested: {
    paddingLeft: 25
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const isAdmin = useSelector(state => state.authSlice.isAdmin);

  useEffect(() => {
    dispatch(GetUserInfo());

    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);


  const menuStatistics = [
    {
      href: `/app/manage/statistics/warehouse-transfer`,
      title: 'Thống kê chuyển kho',
    },
    {
      href: `/app/manage/statistics/active`,
      title: 'Thống kê kích hoạt'
    },
    {
      href: `/app/manage/statistics/service`,
      title: 'Thống kê dịch vụ'
    },
  ];

  const menuDevices = [
    {
      href: `/app/manage/service-packages`,
      title: 'Quản lý gói dịch vụ'
    },
    {
      href: `/app/manage/vehicle-types`,
      title: 'Quản lý loại phương tiện'
    },
    {
      href: `/app/manage/devices`,
      title: 'Quản lý thiết bị'
    },
  ];

  const menuAdvertisement = [
    {
      href: `/app/manage/broadcasts-schedules`,
      title: 'Quản lý lịch phát'
    },
    {
      href: `/app/manage/files-audio`,
      title: 'Quản lý file-audio'
    },
    {
      href: `/app/manage/files-video`,
      title: 'Quản lý file-video'
    },
  ];

  const content = (
    <Box height="100%" display="flex" flexDirection="column" >
      <Box style={{ paddingTop:'16px' }}>
        <List>
         {isAdmin && (
          <NavItem
            icon={`/static/images/bcdn/baocao_dn_tonghop_theonhom.svg`}
            title="Thống kê"
            arrayNested={menuStatistics}
          />
          )}
          {isAdmin && (
            <Box className={classes.menuItem}>
              <NavItem 
                title="Quản trị người dùng" 
                href="/app/manage/users"  
                icon={'/static/images/administration/quan_tri_nguoi_dung.svg'}
              />
            </Box>
          )}
          {isAdmin && (
            <Box className={classes.menuItem}>
              <NavItem title="Quản trị đại lý" href="/app/manage/agencies"  icon={'/static/images/administration/quan_tri_dai_ly.svg'}/>
            </Box>
          )}
          {isAdmin && (
          <Box className={classes.menuItem}>
            <NavItem title="Quản trị nhân viên" href="/app/manage/service-staffs"  icon={'/static/images/administration/quan_tri_nhan_vien.svg'}/>
          </Box>
          )}
          <NavItem
            icon={'/static/images/administration/quan_tri_thiet_bi.svg'}
            title="Quản trị thiết bị"
            arrayNested={menuDevices}
          />

          {!isAdmin && (
            <NavItem
              icon={'/static/images/nav-baocaolaixequa4h.svg'}
              title="Quản trị lái xe"
              arrayNested={[
                {
                  href: `/app/manage/driver/all`,
                  title: 'Danh sách lái xe'
                }
              ]}
            />
          )}
          <NavItem
            icon={'/static/images/administration/quan_tri_quang_cao.svg'}
            title="Quản trị quảng cáo"
            arrayNested={menuAdvertisement}
          />
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
