import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MenuBoxTracking from 'src/app/components/maps/MenuBoxTracking';
import { ArrowRight } from '@material-ui/icons';
import { renderIconCar } from 'src/app/utils/mapService';
import './style.css';
import {
  Button,
  List,
  ListItem,
  Divider,
  Typography,
  Box
} from '@material-ui/core';
import { INFOR_CAR } from 'src/app/constant/config';
import { roles } from 'src/app/constant/roles';
import moment, { duration } from 'moment';
import {
  getListVehicleTracking,
  getListVehicleTrackingAdmin
} from 'src/features/vehicleSlice';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import _orderBy from 'lodash/orderBy';
import { GetUserInfo } from 'src/features/authSlice';
import RotateIcon from 'src/app/utils/RotateIcon';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  ZoomControl,
  Tooltip
} from 'react-leaflet';
import L from 'leaflet';
import mkIcon from '../../assets/mkicon.png';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';
import { getIconStatus } from 'src/app/utils/vehicleService';

const MapOsm = ({ mapElement }) => {
  const [mapRef, setMapRef] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles()

  const agencySelected = useSelector(state => state.vehicleSlice.agencySelected);
  const listVehicleTracking = useSelector(state => state.vehicleSlice.listVehicleTracking);

  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const isAgency = dataLogin && dataLogin.role && dataLogin.role.title === 'agency';
  const isAdmin = dataLogin && dataLogin.role && dataLogin.role.title === 'admin';
  const agencyID = isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  const statisticVehicle = useSelector(state => state.vehicleSlice.statisticVehicleTracking);
  const statisticVehicleTracking = useMemo(() => {
    return statisticVehicle;
  }, [statisticVehicle]);

  let listVehicle = useRef([]);
  useEffect(() => {
    dispatch(GetUserInfo());
  }, []);

  useEffect(() => {
    listVehicle.current = listVehicleTracking;
  }, [listVehicleTracking]);

  useEffect(() => {
    if (agencyID) {
      dispatch(getListVehicleTracking(agencyID));
      const i = window.setInterval(() => {
        dispatch(getListVehicleTracking(agencyID));
      }, 10000);
      return () => {
        window.clearInterval(i);
      };
    }
  }, [agencyID]);

  useEffect(() => {
    if (isAdmin) {
      dispatch(getListVehicleTrackingAdmin());
      const i = window.setInterval(() => {
        dispatch(getListVehicleTrackingAdmin());
      }, 10000);
      return () => {
        window.clearInterval(i);
      };
    }
  }, [isAdmin]);

  const trim = x => {
    return x.replace(/^\(+|\)+$/gm, '');
  };

  const centerDefaultLocal = localStorage.getItem('center')
    ? trim(localStorage.getItem('center')).split(', ')
    : [21.024683, 105.832495];

  const centerDefault = [
    Number(centerDefaultLocal[0]),
    Number(centerDefaultLocal[1])
  ];

  const zoomDefault = localStorage.getItem('zoom')
    ? Number(localStorage.getItem('zoom'))
    : 8;

  const statusGetAll = useSelector(state => state.vehicleSlice.statusGetAll);
  const [ref, setRef] = useState(null);
  const [positionsInfoBox, setPositionsInfoBox] = useState({});
  const [center, setCenter] = useState(centerDefault);
  const [showMenu, setShowMenu] = useState(true);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  const handleMarkerClick = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      setPositionsInfoBox({
        license_plate: vehicle.license_plate,
        lat: Number(vehicle.lat),
        lng: Number(vehicle.lng),
        created_at: vehicle.created_at,
        air_condition_status: vehicle.air_condition_status,
        engine_status: vehicle.engine_status,
        battery: vehicle.battery,
        charge_status: vehicle.charge_status,
        speed_gps: vehicle.speed_gps,
        door_status: vehicle.door_status,
        stop_times: vehicle.stop_times, // số lần dừng đỗ
        is_stopping: vehicle.is_stopping, // đang dừng đỗ
        total_distance: vehicle.total_distance, // Tổng quãng đường đi được trong ngày
        total_overspeed: vehicle.total_overspeed, // số lần quá tốc độ
        total_drive_time: vehicle.total_drive_time, // thời gian lái xe trong ngày
        driver_login: vehicle.driver_login,
        driver_logout: vehicle.driver_logout,
        driver_name: vehicle.driver_name, // tên lái xe
        driver_license: vehicle.driver_license, // bằng lái xe
        non_stop_driving_time: vehicle.non_stop_driving_time
      });
      localStorage.setItem('center', `(${vehicle.lat}, ${vehicle.lng})`);
    }
  };

  useEffect(() => {
    if (positionsInfoBox && positionsInfoBox.lat && positionsInfoBox.lng) {
      setShowInfoWindow(true);
    }
  }, [positionsInfoBox]);

  const handleCloseInfo = () => {
    setShowInfoWindow(false);
    setPositionsInfoBox(null);
  };

  const getVehicle = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      setCenter([Number(vehicle.lat), Number(vehicle.lng)]);
      mapRef.setView([Number(vehicle.lat), Number(vehicle.lng)],15);
    }
  };

  const renderValueInWindowBox = infor => {
    switch (infor.dataKey) {
      case 'created_at':
        return moment
          .unix(positionsInfoBox[infor.dataKey])
          .format('DD/MM HH:mm:ss');

      case 'speed_gps':
        return `${Math.floor(positionsInfoBox[infor.dataKey])} Km/h`;

      case 'engine_status':

      case 'air_condition_status':
        return positionsInfoBox[infor.dataKey] ? 'Bật' : 'Tắt';

      case 'is_stopping':
        return positionsInfoBox[infor.is_stopping] ? 'Dừng' : 'Không';

      default:
        return positionsInfoBox[infor.dataKey] ?? '--';
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setCenter([position.coords.latitude, position.coords.longitude]);
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude
        ]);

        if (!mapRef) return;
        mapRef.flyTo(
          [position.coords.latitude, position.coords.longitude],
          15,
          {
            animate: true
          }
        );
      });
    }
  };

  const [carStatus, setCarStatus] = useState(undefined);
  const handleCarStatus = e => {
    setCarStatus(e);
  };

  const [deviceType, setDeviceType] = useState(undefined);

  const handleDeviceType = e => {
    setDeviceType(e);
  };
//#region Display Icons
  const handleDisplayIcon = useCallback(
    (vehicle, statisticVehicleTracking) => {
      const iconVehicle = getIconStatus(
        vehicle.device_status,
        vehicle.device_type
      );
      const vehicleStates = [
        {
          list: 'lost_gsm_vehicles',
          icon: iconVehicle
        },
        {
          list: 'over_speed_vehicles',
          icon: iconVehicle
        },
        {
          list: 'stop_vehicles',
          icon: iconVehicle
        },
        {
          list: 'running_vehicles',
          icon: iconVehicle
        },
        {
          list: 'lost_gps_vehicles',
          icon: iconVehicle
        }
      ];

      let makerIcon;

      if (vehicle && statisticVehicleTracking) {
        for (const { list, icon } of vehicleStates) {
          if (statisticVehicleTracking[list]?.includes(vehicle.license_plate)) {
            let iconObject = RotateIcon.makeIcon(icon);
            if (typeof iconObject.setRotation === 'function') {
              iconObject = iconObject.setRotation({ deg: vehicle.direction });
            }
            const url =
              typeof iconObject.getUrl === 'function'
                ? iconObject.getUrl()
                : mkIcon;
            makerIcon = L.icon({
              iconUrl: url,
              //  iconSize: [37, 35],
              // iconAnchor: [20, 50],
              popupAnchor: [0, -80]
            });
            break;
          }
        }
      }
      return makerIcon;
    },
    [statisticVehicleTracking]
  );

    const displayLocationIcon = () => {
      let locationCurrent;
      locationCurrent = L.icon({
        iconUrl: `/static/iconSvg/gps.svg`,
        iconSize: [25, 25],
        iconAnchor: [0, 25]
      });
      return locationCurrent;
    };

  return (
    <mapElement>
      <Button className="show_menu" sizeSmall onClick={() => setShowMenu(true)}>
        <ArrowRight />
      </Button>
      <Box
        style={{
          position: 'absolute', bottom: '20px', right: '20px',
          cursor: 'pointer', boxShadow: 'rgba(20, 20, 20, 0.322)  0px 0px 2px 0px', border: '0px !important',
          padding: '8px',color: 'red',width: '45px',height: '45px', display: 'flex', justifyContent: 'center', alignItems:'center',
          backgroundColor:'white', borderRadius: '50%', zIndex: 1000,
        }}
        onClick={() => getCurrentLocation()}
      >
        <Box style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <img src='/static/iconSvg/locationIconv2.svg'/>
          {/* <GpsFixedIcon /> */}
        </Box>
      </Box>

      <MenuBoxTracking
        getVehicle={getVehicle}
        statusGetAll={statusGetAll}
        listVehicle={_orderBy(listVehicle.current, 'license_plate', 'desc')}
        setShowMenu={setShowMenu}
        showMenu={showMenu}
        statusActive={carStatus}
        setCarStatus={handleCarStatus}
        carStatus={carStatus}
        setDeviceType={handleDeviceType}
        deviceType={deviceType}
        defaultAgencySelected={agencyID}
      />

      <MapContainer
        center={center}
        zoom={13}
        maxZoom={18}
        minZoom={6}
        style={{ height: 'calc(100vh - 66px)', position: 'relative' }}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={mapR => setMapRef(mapR)}
        zoomAnimation={true}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
          <FullscreenControl 
            forceSeparateButton={true} 
            position="topright" 
          />
          <ZoomControl position="topright"/>
        
          {listVehicleTracking && listVehicleTracking.length > 0
            ? listVehicleTracking.map((vehicle, index) => {
                if (carStatus) {
                  if (carStatus == vehicle.device_status) {
                    return (
                      <>
                        {vehicle && vehicle.lat && vehicle.lng ? (
                          <Marker
                            key={index}
                            style={{ transform: 'rotate(90deg);' }}
                            options={{
                              rotation: 180
                            }}
                            position={[
                              Number(vehicle.lat),
                              Number(vehicle.lng)
                            ]}
                            eventHandlers={{
                              click: () => handleMarkerClick(vehicle)
                            }}
                            icon={handleDisplayIcon(vehicle,statisticVehicleTracking)}
                          >
                            {
                              <>
                                <Popup
                                  position={[
                                    Number(vehicle.lat),
                                    Number(vehicle.lng)
                                  ]}
                                  keepInView={true}
                                  // minWidth={250}
                                  // maxHeight="auto"
                                  autoPanPaddingTopLeft={[20, 20]}
                                >
                                  <Box
                                    style={{
                                      // background:'red !important',
                                      width: '100%',
                                      height: '100%',
                                      overflowY: 'scroll'
                                    }}
                                  >
                                    <Typography
                                      color="primary"
                                      style={{
                                        color: '#C62222',
                                        fontSize: '20px',
                                        fontWeight: 600
                                      }}
                                    >
                                      Thông tin xe:
                                    </Typography>
                                    <List
                                      component="nav"
                                      style={{
                                        padding: '0 !important',
                                        width: '375px !important'
                                      }}
                                    >
                                      {INFOR_CAR.map((infor, index) => (
                                        <>
                                          <ListItem
                                            disableGutters
                                            style={{
                                              padding: '0',
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              display: 'inline-block'
                                              //background:'#b4a7d6'
                                            }}
                                          >
                                            <div style={{ float: 'left' }}>
                                              <img
                                                src={`/static/iconSvg/${infor.icon}.svg`}
                                                style={{
                                                  paddingRight: '5px',
                                                  marginLeft: '0 !important'
                                                }}
                                              />
                                              <b> {infor.label} </b>
                                            </div>

                                            <div
                                              style={{
                                                float: 'right',
                                                textAlign: 'right'
                                              }}
                                            >
                                              {renderValueInWindowBox(infor)}
                                            </div>
                                          </ListItem>
                                          <Divider />
                                        </>
                                      ))}
                                    </List>
                                  </Box>
                                </Popup>

                                <Tooltip
                                  direction="bottom"
                                  permanent
                                  position={[
                                    Number(vehicle.lat),
                                    Number(vehicle.lng)
                                  ]}
                                  opacity={1}
                                  className={'tooltip-label'}
                                >
                                  <Typography
                                    style={{
                                      backgroundColor: 'rgb(255, 255, 255)',
                                      padding: '0.2em',
                                      fontSize: '12px',
                                      width: 'max-content !important',
                                      borderRadius: '0.1em',
                                      color: 'rgb(0, 0, 0)',
                                      fontWeight: 'bold'
                                      //  marginTop: '10px'
                                    }}
                                  >
                                    {vehicle.license_plate}
                                  </Typography>
                                </Tooltip>
                              </>
                            }
                          </Marker>
                        ) : null}
                      </>
                    );
                  }
                } else {
                  return (
                    <>
                      {vehicle && vehicle.lat && vehicle.lng ? (
                        <Marker
                          key={index}
                          options={{
                            rotation: 180
                          }}
                          position={[Number(vehicle.lat), Number(vehicle.lng)]}
                          icon={handleDisplayIcon(
                            vehicle,
                            statisticVehicleTracking
                          )}
                          eventHandlers={{
                            click: e => handleMarkerClick(vehicle)
                          }}
                        >
                          <>
                            {
                              <Popup
                                position={[
                                  Number(vehicle.lat),
                                  Number(vehicle.lng)
                                ]}
                                keepInView={true}
                                autoPanPaddingTopLeft={[20, 0]}
                              >
                                <Box
                                  style={{
                                    overflowY: 'scroll',
                                    width: '100%',
                                    height: '100%',
                                    padding: '0px 10px 10px 10px',
                                    borderRadius: '7px',
                                    background: '#ffffff',
                                    boxShadow:
                                      '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0
                                  }}
                                >
                                  <Typography
                                    color="primary"
                                    style={{
                                      color: '#C62222',
                                      fontSize: '20px',
                                      fontWeight: 600
                                    }}
                                  >
                                    Thông tin xe:
                                  </Typography>
                                  <List
                                    component="nav"
                                    style={{
                                      padding: '0 !important',
                                      width: '375px !important'
                                    }}
                                  >
                                    {INFOR_CAR.map((infor, index) => (
                                      <>
                                        <ListItem
                                          disableGutters
                                          style={{
                                            padding: '0',
                                            paddingTop: '5px',
                                            paddingBottom: '5px',
                                            display: 'inline-block'
                                            //background:'#b4a7d6'
                                          }}
                                        >
                                          <div style={{ float: 'left' }}>
                                            <img
                                              src={`/static/iconSvg/${infor.icon}.svg`}
                                              style={{
                                                paddingRight: '5px',
                                                marginLeft: '0 !important'
                                              }}
                                            />
                                            <b> {infor.label} </b>
                                          </div>

                                          <div
                                            style={{
                                              float: 'right',
                                              textAlign: 'right'
                                            }}
                                          >
                                            {renderValueInWindowBox(infor)}
                                          </div>
                                        </ListItem>
                                        <Divider />
                                      </>
                                    ))}
                                  </List>
                                </Box>
                              </Popup>
                            }

                            <Tooltip
                              direction="center"
                              permanent
                              position={[
                                Number(vehicle.lat),
                                Number(vehicle.lng)
                              ]}
                              opacity={1}
                              className={'tooltip-label'}
                            >
                              <Typography
                                style={{
                                  backgroundColor: 'rgb(255, 255, 255)',
                                  padding: '0.2em',
                                  fontSize: '12px',
                                  width: 'max-content !important',
                                  borderRadius: '0.1em',
                                  color: 'rgb(0, 0, 0)',
                                  fontWeight: 'bold'
                                  //  marginTop: '10px'
                                }}
                              >
                                {vehicle.license_plate}
                              </Typography>
                            </Tooltip>
                          </>
                        </Marker>
                      ) : null}
                    </>
                  );
                }
              })
            : null}

          {currentLocation && (
            <Marker icon={displayLocationIcon()} position={currentLocation}>
              <Tooltip
                style={{
                  background: 'rgba(0, 0, 0, 0) !important',
                  boxShadow: 'none !important',
                  padding : '2px',
                  position:'absolute',
                  top:5,
                  left:-22
                }}
                position={currentLocation}
                direction="right"
                permanent
              >
                <Box class="your_location"> Vị trí của bạn</Box>
              </Tooltip>
            </Marker>
          )}
        
      </MapContainer>
    </mapElement>
  );
};

const useStyles = makeStyles(theme => ({
 
}));

export default React.memo(MapOsm);
