import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const ToolBarEdit = ({ closeToolbarRef }) => {
  const handleClose = (isSave) => {
      if(!closeToolbarRef) return;
      closeToolbarRef(isSave);
  };

  return (
    <Toolbar className="device-active-toolbar">
      <Typography variant="h5" 
        style={{
          fontWeight: 600,
          flex: 1,
          fontSize: 18,
        }}
      >
        Kích hoạt thiết bị
      </Typography>
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => handleClose()}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </Toolbar>
  );
}


export default ToolBarEdit;
