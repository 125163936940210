import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { renderIconCar } from 'src/app/utils/mapService';
import _size from 'lodash/size';

import { List, ListItem, Divider, Typography, Box } from '@material-ui/core';
import { INFOR_CAR } from 'src/app/constant/config';
import moment from 'moment';

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  ZoomControl,
  Tooltip
} from 'react-leaflet';
import L from 'leaflet';
import mkIcon from '../../assets/mkicon.png';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import { ContentCarInfo } from 'src/app/components/popupInfocar/ContentCarInfo';
import RotateIcon from 'src/app/utils/RotateIcon';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { getIconStatus } from 'src/app/utils/vehicleService';
import './style.css';

function MapOsmCamera({ listVehicle, center, zoom, getSetView }) {
  const centerInRedux = useSelector(state => state.vehicleSlice.centerMap);
  const centerMap = _size(center) > 0 ? center : [centerInRedux['lat'], centerInRedux['lng']];
  const [positionsInfoBox, setPositionsInfoBox] = useState({});
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [mapRef, setMapRef] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  const statisticVehicleTracking = useSelector(
    state => state.vehicleSlice.statisticVehicleTracking
  );

  console.log('center ===>',center, centerInRedux);

  useEffect(() => {
    if(mapRef !== null){
      getSetView(mapRef)
    }
  }, [mapRef]);

  const handleMarkerClick = vehicle => {
    if (vehicle && vehicle.lat && vehicle.lng) {
      setPositionsInfoBox({
        license_plate: vehicle.license_plate,
        lat: Number(vehicle.lat),
        lng: Number(vehicle.lng),
        created_at: vehicle.created_at,
        air_condition_status: vehicle.air_condition_status,
        battery: vehicle.battery,
        charge_status: vehicle.charge_status,
        speed_gps: vehicle.speed_gps,
        door_status: vehicle.door_status
      });
      setShowInfoWindow(true);
    }
  };

  const handleCloseInfo = () => {
    setShowInfoWindow(false);
    setPositionsInfoBox();
  };

  //#region Display Icons
  const handleDisplayIcon = useCallback(
    (vehicle, statisticVehicleTracking) => {
      const iconVehicle = getIconStatus(
        vehicle.device_status,
        vehicle.device_type
      );
      const vehicleStates = [
        {
          list: 'lost_gsm_vehicles',
          icon: iconVehicle
        },
        {
          list: 'over_speed_vehicles',
          icon: iconVehicle
        },
        {
          list: 'stop_vehicles',
          icon: iconVehicle
        },
        {
          list: 'running_vehicles',
          icon: iconVehicle
        },
        {
          list: 'lost_gps_vehicles',
          icon: iconVehicle
        }
      ];

      let makerIcon;

      if (vehicle && statisticVehicleTracking) {
        for (const { list, icon } of vehicleStates) {
          if (statisticVehicleTracking[list]?.includes(vehicle.license_plate)) {
            let iconObject = RotateIcon.makeIcon(icon);
            if (typeof iconObject.setRotation === 'function') {
              iconObject = iconObject.setRotation({ deg: vehicle.direction });
            }
            const url =
              typeof iconObject.getUrl === 'function'
                ? iconObject.getUrl()
                : mkIcon;
            makerIcon = L.icon({
              iconUrl: url,
              //  iconSize: [37, 35],
              // iconAnchor: [20, 50],
              popupAnchor: [0, -80]
            });
            break;
          }
        }
      }
      return makerIcon;
    },
    [statisticVehicleTracking]
  );
  //#endregion
  const displayLocationIcon = () => {
    let locationCurrent = L.icon({
      iconUrl: `/static/iconSvg/gps.svg`,
      iconSize: [25, 25],
      iconAnchor: [1, 25],
    });
    return locationCurrent;
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        // setCenter([position.coords.latitude, position.coords.longitude]);
        setCurrentLocation([
          position.coords.latitude,
          position.coords.longitude
        ]);

        if (!mapRef) return;
        mapRef.flyTo(
          [position.coords.latitude, position.coords.longitude],
          13,
          {
            animate: true
          }
        );
      });
    }
  };

  return (
    <>
      <Box
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',

          cursor: 'pointer',
          boxShadow: 'rgba(20, 20, 20, 0.322)  0px 0px 2px 0px',
          border: '0px !important',

          padding: '8px',
          color: 'red',
          width: '45px',
          height: '45px',

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',

          backgroundColor: 'white',
          borderRadius: '50%',
          zIndex: 1000
        }}
        onClick={() => getCurrentLocation()}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <img src="/static/iconSvg/locationIconv2.svg" />
          {/* <GpsFixedIcon /> */}
        </Box>
      </Box>

      <MapContainer
        center={centerMap}
        zoom={zoom}
        maxZoom={18}
        minZoom={6}
        style={{ position:'relative', height: '93vh' }}
        zoomControl={false}
        scrollWheelZoom={true}
        whenCreated={mapR => setMapRef(mapR)}
        zoomAnimation={true}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}.png?key=ZLjliqReTSXGIpeOzHPo"
        />
        <div style={{marginRight: 10}}>
          <FullscreenControl forceSeparateButton={true} position="topright" />
          <ZoomControl position="topright" />
        </div>
        <>
          {listVehicle &&
            listVehicle.map((vehicle, index) => (
              <Marker
                key={index}
                style={{ transform: 'rotate(90deg);' }}
                options={{
                  rotation: 180
                }}
                position={[Number(vehicle.lat), Number(vehicle.lng)]}
                eventHandlers={{
                  click: () => handleMarkerClick(vehicle)
                }}
                icon={handleDisplayIcon(vehicle, statisticVehicleTracking)}
              >
                <Popup
                  position={[Number(vehicle.lat), Number(vehicle.lng)]}
                  keepInView={true}
                  //minWidth={390}
                  minWidth={250}
                  maxHeight="auto"
                  autoPanPaddingTopLeft={[20, 10]}
                >
                  <ContentCarInfo positionsInfoBox={positionsInfoBox} />
                </Popup>

                <Tooltip
                  direction="bottom"
                  permanent
                  position={[Number(vehicle.lat), Number(vehicle.lng)]}
                  opacity={1}
                  className={'tooltip-label'}
                >
                  <Typography
                    style={{
                      backgroundColor: 'rgb(255, 255, 255)',
                      padding: '0.2em',
                      fontSize: '12px',
                      width: 'max-content !important',
                      borderRadius: '0.1em',
                      color: 'rgb(0, 0, 0)',
                      fontWeight: 'bold'
                    }}
                  >
                    {vehicle.license_plate}
                  </Typography>
                </Tooltip>
              </Marker>
            ))}

          {currentLocation && (
            <Marker icon={displayLocationIcon()} position={currentLocation}>
              <Tooltip
                style={{
                  background: 'rgba(0, 0, 0, 0) !important',
                  boxShadow: 'none !important',
                  marginTop: '5px !important'
                }}
                position={currentLocation}
                direction="right"
                permanent
              >
                <div class="your_location"> Vị trí của bạn</div>
              </Tooltip>
            </Marker>
          )}
        </>
      </MapContainer>
    </>
  );
}

export default MapOsmCamera;
