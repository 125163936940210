import { Phone } from "react-feather";

export const listStaffMock = [
    {
        id: 'dNSASMEKHCfxR',
        active_device: 'SNH-SRB',
        address: 'Ha Dong - Ha Noi',
        created_at: '2023-11-16T07:14:34.210Z',
        mail: 'admin@example.com',
        name: 'Vntech24h_Admin',
        phone: '0987654321',
        status: 'active',
        total_device: 10,
        province:'Hà Nội',
        agency_type: 'Công ty A',
        password: 'password123',
        updated_at: '2023-11-16T07:14:34.210Z',
    },
    {
        id: 'tlaninkMEKhoCu',
        active_device: 'AOE-SRB',
        address: 'TPHCM - Quan 1',
        created_at: '2023-12-08T07:14:34.210Z',
        mail: 'dvt1701@example.com',
        name: 'Vntech24h_TuanDo',
        phone: '0389054321',
        status: 'active',
        total_device: 15,
        province:'Lâm Dong',
        agency_type: 'Công ty B',
        password: 'password123',
        updated_at: '2023-12-08T07:14:34.210Z',
    },
    {
        id: 'mtFe5a5d986e9',
        active_device: 'ARG-OOP',
        address: 'Trieu Thanh - Thanh Hóa',
        created_at: '2023-10-15T07:14:34.210Z',
        mail: 'admin@example.com',
        name: 'Vntech24h_Admin',
        phone: '0987654321',
        status: 'active',
        total_device: 10,
        province:'Ninh Bình',
        agency_type: 'Công ty C',
        password: 'password123',
        updated_at: '2023-10-15T07:14:34.210Z',
    },
]