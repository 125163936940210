import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { MenuOpen as MenuOpenIcon } from '@material-ui/icons';
import {
  MenuItem,
  TextField,
  IconButton,
  InputBase,
  Paper,
  Button,
  Box,
  Typography,
  Select,
  makeStyles,
  SvgIcon,
  InputAdornment,
} from '@material-ui/core';
import { CAR_STATUS } from 'src/app/constant/config';

import {
  getListVehicle,
  getListVehicleTracking,
  setGetListLoading,
  setAgencySelected,
  getVehicleLicensePlateByAdmin
} from 'src/features/vehicleSlice';

import { getListDeviceType } from 'src/features/deviceTypeSlice';
import { getListAgencies } from 'src/features/agencySlice';
import { roles } from 'src/app/constant/roles';
import { STATUS_API } from 'src/app/constant/config';
import { useSearchParams } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 18px 5px 0px',
    width: 365,
    // marginBottom: '20px',
    boxShadow: 'none'
  },
  input: {
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  titleLotrinh: {
    fontSize: '18px',
    color: '#C62222',
    fontWeight: 700,
    marginBottom: '10px'
  },
  titleTime: {
    fontSize: '18px',
    color: '#0C1132',
    fontWeight: 700,
    marginBottom: '10px'
  },
  formControl: {
    height: '60px',
    marginTop: '10px',
    width: '360px',
    marginBottom: '10px'
  },
  titleHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectDropdown: {
    padding: '5 5 5 5 '
  },
  dropDown: {
    width: '98%'
  }
}));

export default function ToolBar({
  setShowMenu,
  setDeviceType,
  deviceType
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState();

  const listDeviceType = useSelector(
    state => state.deviceTypeSlice.listDeviceType
  );
  const listAgencies = useSelector(state => state.agencySlice.listAgencies);
  const dataLogin = useSelector(state => state.authSlice.dataLogin);
  const listLicensePlate = useSelector(
    state => state.vehicleSlice.listLicensePlate
  );

  const isAdmin =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.ADMIN;
  const isAgency =
    dataLogin && dataLogin.role && dataLogin.role.title === roles.AGENCY;
  const agencyID =
    isAgency && dataLogin.agency ? dataLogin.agency.id : undefined;

  useEffect(() => {
    dispatch(getVehicleLicensePlateByAdmin());
  }, []);

  const trim = x => {
    return x?.replace(/^\(+|\)+$/gm, '');
  };

  const handleSearchDevice = e => {
    const newValue = e.target.value;
    setValue(newValue);
    if (isAdmin) {
      dispatch(getListVehicle({ license_plate: newValue }));
    } else if (isAgency) {
      dispatch(
        getListVehicle({ license_plate: newValue, agency_id: agencyID })
      );
    }
  };

  const handleChangeInput = e => {
    e.preventDefault();
    const newValue = e.target.value;
    setValue(newValue);
  };

  const keyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      const newValue = e.target.value;
      setValue(newValue);
      if (isAdmin) {
        dispatch(getListVehicle({ license_plate: newValue }));
      } else if (agencyID && isAgency) {
        dispatch(
          getListVehicle({ license_plate: newValue, agency_id: agencyID })
        );
      }
    }
  };

  const [id, setId] = useState(null);
  let i;
  const [urlString, setUrl] = useState(`${id}`);
  const [agencyParams, setAgencyParams] = useState(0);
  const agencySelectedParams = searchParams.get('agency');

  useEffect(() => {
    if (agencySelectedParams) {
      setAgencyParams(JSON.parse(agencySelectedParams));
      dispatch(setGetListLoading(STATUS_API.LOADING));
      dispatch(setAgencySelected(agencySelectedParams));
      setId(agencySelectedParams);
      setUrl(agencySelectedParams);
      dispatch(getListVehicleTracking(agencySelectedParams));
    }
  }, [agencySelectedParams]);

  const handleChangeAgency = e => {
    window.clearInterval(i);
    e.preventDefault();
    dispatch(setGetListLoading(STATUS_API.LOADING));
    dispatch(setAgencySelected(e.target.value));
    setId(e.target.value);
    setUrl(e.target.value);
    setSearchParams({ ['agency']: JSON.stringify(e.target.value) });
    dispatch(getListVehicleTracking(e.target.value));
  };

  // Get list device type
  useEffect(() => {
    dispatch(getListDeviceType());
    return () => {
      dispatch(getListVehicle({ license_plate: '' }));
    }
  }, []);

  useEffect(() => {
    // if (id) {
    //   i = window.setInterval(() => {
    dispatch(getListVehicleTracking(urlString));
    //   }, 3000);
    //   return () => {
    //     window.clearInterval(i);
    //   };
    // }
  }, [id, urlString]);

  useEffect(() => {
    dispatch(getListAgencies());
  }, []);

  const handleChangeFilterDeviceType = e => {
    setDeviceType(e.target.value);
  };

  return (
    <Paper component="form" className={classes.root}>
      <div>
        <div className={classes.titleHeader}>
          <h6 className={classes.titleLotrinh}>Giám sát</h6>
          <Button title="Ẩn Menu" onClick={() => setShowMenu(false)}>
            {' '}
            <MenuOpenIcon />{' '}
          </Button>
        </div>

        <Box
          style={{
            // border: '1px solid #E5E5E8',
            borderRadius: '4px',
            marginBottom: '10px',
            position: 'relative'
          }}
        >
          {/* <IconButton 
              onClick={e => handleSearchDevice(e)} 
              aria-label="search"
              style={{
                position:'absolute',
                top: 7,
                left: 0,
                zIndex: 10,
              }}
          >
            <SearchIcon />
          </IconButton> */}
          {/* <InputBase
            style={{flex: 1}}
            size="small"
            placeholder="Tìm kiếm phương tiện"
            inputProps={{ 'aria-label': 'search google maps' }}
            value={value ? value : ''}
            onChange={e => handleChangeInput(e)}
            onKeyDown={keyPress}
          /> */}

          {listLicensePlate && (
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              size="small"
              disableClearable
              options={listLicensePlate}
              // value={value ? value : ''}
              renderInput={params => (
                <TextField
                  {...params}
                  margin="dense"
                  className="input-no-fieldset"
                  variant="outlined"
                  placeholder="Tìm kiếm thiết bị"
                  onChange={e => handleChangeInput(e)}
                  onKeyDown={keyPress}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <InputAdornment position="start" style={{cursor:'pointer'}}>
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon onClick={e => handleSearchDevice(e)} />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                
                />
              )}
            />
          )}
        </Box>

        <Box
          style={{
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column ',
              width: '48%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.3em',
              marginBottom: '24px',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={deviceType || ''}
              onChange={e => handleChangeFilterDeviceType(e)}
              displayEmpty
              disableUnderline
            >
              <MenuItem value="">
                <small> Tất cả thiết bị </small>
              </MenuItem>
              {listDeviceType &&
                listDeviceType.map(deviceType => (
                  <MenuItem value={deviceType.name}>{deviceType.name}</MenuItem>
                ))}
            </Select>
          </Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column ',
              width: '48%',
              borderColor: '#E5E5E8',
              borderWidth: '1px',
              borderStyle: 'solid',
              padding: '0.3em',
              marginBottom: '24px',
              borderRadius: '5px'
            }}
          >
            <Select
              style={{ width: '100%' }}
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              defaultValue={''}
              value={agencyParams || ''}
              onChange={e => handleChangeAgency(e)}
              displayEmpty
              disableUnderline
            >
              <MenuItem value="">
                <small> Chọn đại lý </small>
              </MenuItem>
              {listAgencies &&
                listAgencies.map(agency => (
                  <MenuItem value={agency.id}>{agency.name}</MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
      </div>
    </Paper>
  );
}
